import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CatchmentAlignment from "./catchmentAlignment/CatchmentAlignment";
import CatchmentAlignmentSubtitle from "./catchmentAlignment/Subtitle";
import CompetitionAlignment from "./competitionAlignment/CompetitionAlignment";
import CompetitionAlignmentSubtitle from "./competitionAlignment/Subtitle";
import FootfallAlignment from "./footfallAlignment/FootfallAlignment";
import FootfallAlignmentSubtitle from "./footfallAlignment/Subtitle";
import ProductStoreFitSubchapter from "./productStoreFit/ProductStoreFit";
import ProductStoreFitSubtitle from "./productStoreFit/Subtitle";
import Subtitle from "./Subtitle";

import { selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { selectOpenModalActiveStep, setActiveStep, showStoreRangeFilters } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";

const ProductStoreFit: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedStore = useAppSelector(selectSelectedStore);
    const selectedRange = useAppSelector(selectSelectedRange);
    const openModalActiveStep = useAppSelector(selectOpenModalActiveStep);

    React.useEffect(() => {
        if (!selectedStore || !selectedRange) {
            dispatch(setActiveStep(openModalActiveStep));
            dispatch(showStoreRangeFilters());
        }
    }, [dispatch, selectedStore, selectedRange, openModalActiveStep]);

    return (
        <>
            {selectedStore && selectedRange && (
                <Chapter
                    title="Product store fit"
                    subtitle={<Subtitle />}
                    dataCy="product-store-fit-chapter"
                >
                    <Subchapter
                        title="Product store fit"
                        subtitle={<ProductStoreFitSubtitle />}
                        dataCy="product-store-fit-subchapter"
                    >
                        <ProductStoreFitSubchapter />
                    </Subchapter>
                    <Subchapter
                        title="Catchment alignment"
                        subtitle={<CatchmentAlignmentSubtitle />}
                        dataCy="catchment-alignment-subchapter"
                    >
                        <CatchmentAlignment />
                    </Subchapter>
                    <Subchapter
                        title="Footfall alignment"
                        subtitle={<FootfallAlignmentSubtitle />}
                        dataCy="footfall-alignment-subchapter"
                    >
                        <FootfallAlignment />
                    </Subchapter>
                    <Subchapter
                        title="Competition alignment"
                        subtitle={<CompetitionAlignmentSubtitle />}
                        dataCy="competition-alignment-subchapter"
                    >
                        <CompetitionAlignment />
                    </Subchapter>
                </Chapter>
            )}
        </>
    );
};

export default ProductStoreFit;
