import themes from "themes";

interface tooltipHTMLProps {
    values?: (string | undefined)[],
    header?: (string | undefined),
    categorySpan?: boolean[];
    categoryFormattingArr?: (string | undefined)[];
    valueFormattingArr?: (string | undefined)[];
    overrideDefaultCategoryFormatting?: boolean;
    overrideDefaultValueFormatting?: boolean;
    overrideDefaultHeaderFormatting?: boolean;
    categoryFormatting?: string;
    valueFormatting?: string;
    headerFormatting?: string
}

const capitaliseFirstLetter = (string: string) => {
    const s = string.charAt(0).toUpperCase() + string.slice(1);
    return s;
};

const tooltipHTML = (categories: (string | undefined)[], props: tooltipHTMLProps) => {
    /*
    Creates an HTML tooltip for Highcharts that might look something like this
    ----------------
    Header
    Category 1     5
    Category 2    98
    Category 3  £10m
    ----------------

    If values in an array are blank, it can also separate out different super categories. Eg
    ------------------------
    Header
    Super category 1
    Category 1          10.9
    Category 2         12.8%
    Super category 2
    Category 1          12.1
    Category 2         57.1%
    ------------------------

    Available props:
        - values: array of values to go in other column to categories
        - header: a title for the header row
        - categoryFormattingArr: array of formatting for categories eg colours, font sizes etc
        - valueFormattingArr: same as above but for values
        - overrideDefaultCategoryFormatting: boolean for whether to override default category formatting with
          some other formatting for categories
        - overrideDefaultValueFormatting: same as above but for values
        - overrideDefaultHeaderFormatting: same as above but for the header row
        - categoryFormatting: string for formatting all categories that is combined with the default formatting
          unless overridden (see above)
        - valueFormatting: string for formatting all values that is combined with the default formatting
          unless overridden (see above)
        - headerFormatting: string for formatting the header that is combined with the default formatting
          unless overridden (see above)
    */

    const theme = themes.dark;
    const fontFamily = theme.typography.fontFamily;

    const defaultCategoryFormatting = `font-family:${fontFamily};font-size: 14px;`;
    const defaultValueFormatting = `text-align:right;font-weight:bold;font-family:${fontFamily}; font-size: 14px;`;
    const defaultHeaderFormatting = `font-family:${fontFamily};font-weight:bold;font-size: 14px;`;

    props.values = props.values || [];
    props.categoryFormattingArr = props.categoryFormattingArr || [];
    props.valueFormattingArr = props.valueFormattingArr || [];
    props.categorySpan = props.categorySpan || [];
    props.overrideDefaultCategoryFormatting = props.overrideDefaultCategoryFormatting || false;
    props.overrideDefaultValueFormatting = props.overrideDefaultValueFormatting || false;
    props.overrideDefaultHeaderFormatting = props.overrideDefaultHeaderFormatting || false;
    props.categoryFormatting = props.overrideDefaultCategoryFormatting ? props.categoryFormatting || "" : `${props.categoryFormatting || ""};${defaultCategoryFormatting}`;
    props.valueFormatting = props.overrideDefaultValueFormatting ? props.valueFormatting || "" : `${props.valueFormatting || ""};${defaultValueFormatting}`;
    props.headerFormatting = props.overrideDefaultHeaderFormatting ? props.headerFormatting || "" : `${props.headerFormatting || ""};${defaultHeaderFormatting}`;

    let rows = "";
    let rowCategoryFormat = "";
    let rowValue = "";
    let rowValueFormat = "";

    if (props.header !== "") {
        rows += `<tr><td colspan="2" style="${props.headerFormatting}">${props.header}</td></tr>`;
    }

    for (let i = 0; i < categories.length; i++) {
        rowCategoryFormat = `${props.categoryFormatting};${props.categoryFormattingArr[i] || ""}`;
        rowValue = props.values[i] || "";
        rowValueFormat = `${props.valueFormatting};${props.valueFormattingArr[i] || ""}`;
        const spanValue = props.categorySpan[i] ? `2` : `1`;

        rows += `<tr>
            <td colspan="${spanValue}" style="${rowCategoryFormat}">${categories[i]}</td>
            <td style="${rowValueFormat}"><b>${rowValue}</b></td>
        </tr>`;
    }

    return rows;
};

const tooltipHTMLOld = (categories: any[], values: any[], categoryFormattingArr = [], valueFormattingArr = [], categoryFormatting = "font-family:Open Sans", valueFormatting = "text-align: right;font-weight:bold;font-family:Open Sans") => {
    let rows = "";
    let categoryFormat = "";
    let valueFormat = "";

    for (let i = 0; i < categories.length; i++) {
        if (categoryFormattingArr[i] == null) {
            categoryFormat = categoryFormatting;
        } else {
            categoryFormat = categoryFormatting + "; " + categoryFormattingArr[i];
        }

        if (valueFormattingArr[i] == null) {
            valueFormat = valueFormatting;
        } else {
            valueFormat = valueFormatting + "; " + valueFormattingArr[i];
        }
        rows += "<tr><td style=\"" + categoryFormat + "\">" + categories[i] + "</td><td style=\"" + valueFormat + "\"><b> " + values[i] + "</b></td></tr>";
    }

    return rows;
};

const verticalTooltipCategoryValuePairHTML = (category: string, value: string) => {
    return `<p><span style="font-size:12; font-weight:400; color:${themes.dark.palette.text.secondary}">${category}</span><br>
        <span style="font-weight:600;font-size:14">${value}</span></p>`;
};

const truncate = (string: string, maxLength: number) => {
    const s = string.length > maxLength ? string.slice(0, maxLength) + "..." : string;
    return s;
};

const stringUtils = {
    capitaliseFirstLetter,
    tooltipHTML,
    tooltipHTMLOld,
    verticalTooltipCategoryValuePairHTML,
    truncate
};

export default stringUtils;
