import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedRange } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);

    return (
        <>
            Discover the best stores in {selectedPartner?.name}'s estate for {selectedRange?.name} by comparing estimated and
            optimised sales. Pinpoint locations with the greatest headroom to refine product placement and drive growth.
        </>
    );
};

export default Subtitle;
