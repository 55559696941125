import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { Partner } from "modules/customer/tools/product/partner";
import { selectPartners } from "modules/customer/tools/product/productSlice";

import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";

interface FiltersVisibility {
    isVisible: boolean
}

interface SliderThresholds {
    minPercentileThreshold: number,
    maxPercentileThreshold: number,
    percentileThresholds: number[]
}

export enum PartnerFilterStep {
    SelectPartner,
    // SelectPartnerGroup
}

export interface PartnersSearch {
    name: string
}

export interface PartnersFilter {
    headroom: number[],
    optimisedSales: number[],
    totalEstimatedSales: number[],
    clientSourcedSales: number[]
}

export enum PartnersSortField {
    PartnerName,
    Headroom,
    OptimisedSales,
    TotalEstimatedSales,
    ClientSourcedSales
}

interface PartnersSort {
    field: PartnersSortField,
    direction: SortDirection
}

interface FiltersState {
    partnerFiltersVisibility: FiltersVisibility,
    activeStep: PartnerFilterStep,
    partnersSearch: PartnersSearch,
    candidatePartner?: Partner,
    candidatePartnerGroup?: string,
    partnersFilter: PartnersFilter,
    partnersSort: PartnersSort
}

const initialState: FiltersState = {
    partnerFiltersVisibility: {
        isVisible: false
    },
    activeStep: PartnerFilterStep.SelectPartner,
    partnersSearch: {
        name: ""
    },
    candidatePartner: undefined,
    candidatePartnerGroup: undefined,
    partnersFilter: {
        headroom: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        optimisedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        totalEstimatedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        clientSourcedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    partnersSort: {

        field: PartnersSortField.PartnerName,
        direction: SortDirection.ASC
    }
};

const partnerFiltersSlice = createSlice({
    name: "customer/tools/product/partnerFilters",
    initialState,
    reducers: {
        showPartnerFilters: (state) => {
            state.activeStep = initialState.activeStep;
            state.partnerFiltersVisibility.isVisible = true;
        },
        hidePartnerFilters: (state) => {
            state.partnerFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<PartnerFilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setPartnersSearch: (state, action: PayloadAction<PartnersSearch>) => {
            state.partnersSearch = action.payload;
        },
        clearPartnersSearch: (state) => {
            state.partnersSearch = initialState.partnersSearch;
        },
        setCandidatePartner: (state, action: PayloadAction<Partner>) => {
            state.candidatePartner = action.payload;
        },
        clearCandidatePartner: (state) => {
            state.candidatePartner = initialState.candidatePartner;
        },
        setCandidatePartnerGroup: (state, action: PayloadAction<string>) => {
            state.candidatePartnerGroup = action.payload;
        },
        clearCandidatePartnerGroup: (state) => {
            state.candidatePartnerGroup = initialState.candidatePartnerGroup;
        },
        setPartnersFilter: (state, action: PayloadAction<PartnersFilter>) => {
            state.partnersFilter = action.payload;
        },
        clearPartnersFilter: (state) => {
            state.partnersFilter = initialState.partnersFilter;
        },
        setPartnersSort: (state, action: PayloadAction<PartnersSort>) => {
            state.partnersSort = action.payload;
        },
        clearPartnersSort: (state) => {
            state.partnersSort = initialState.partnersSort;
        }
    },
    extraReducers: (builder: any) => {

    }
});

export const {
    showPartnerFilters,
    hidePartnerFilters,
    setActiveStep,
    setPartnersSearch,
    setCandidatePartner,
    setCandidatePartnerGroup,
    clearActiveStep,
    clearCandidatePartner,
    clearCandidatePartnerGroup,
    clearPartnersSearch,
    setPartnersFilter,
    clearPartnersFilter,
    setPartnersSort,
    clearPartnersSort
} = partnerFiltersSlice.actions;

export const clearPartnerFilters = (): AppThunk => async (dispatch) => {
    dispatch(partnerFiltersSlice.actions.hidePartnerFilters());
    dispatch(partnerFiltersSlice.actions.clearActiveStep());
    dispatch(partnerFiltersSlice.actions.clearPartnersSearch());
    dispatch(partnerFiltersSlice.actions.clearCandidatePartner());
    dispatch(partnerFiltersSlice.actions.clearCandidatePartnerGroup());
    dispatch(partnerFiltersSlice.actions.clearPartnersFilter());
    dispatch(partnerFiltersSlice.actions.clearPartnersSort());
};

export const selectPartnerFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.partnerFilters.partnerFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.activeStep;
};

export const selectPartnersSearch = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.partnersSearch;
};

export const selectCandidatePartner = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.candidatePartner;
};

export const selectCandidatePartnerGroup = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.candidatePartnerGroup;
};

export const selectPartnersFilter = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.partnersFilter;
};

export const selectPartnersSort = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.partnersSort;
};

const generateSliderThresholds = (values: number[]): SliderThresholds => {
    const percentileThresholds = mathUtils.percentileThresholds(values, 5);
    percentileThresholds.push(values[(values.length - 1)]);
    return {
        minPercentileThreshold: percentileThresholds[0],
        maxPercentileThreshold: percentileThresholds[percentileThresholds.length - 1],
        percentileThresholds
    };
};

export const selectHeadroom = createSelector(
    (state: RootState) => selectPartners(state),
    (partners) => {
        const headroom = partners
            .map(partner => partner.sales.salesHeadroom)
            .sort((a, b) => a - b);
        return generateSliderThresholds(headroom);
    }
);

export const selectOptimisedSales = createSelector(
    (state: RootState) => selectPartners(state),
    (partners) => {
        const optimisedSales = partners
            .map(partner => partner.sales.optimisedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(optimisedSales);
    }
);

export const selectTotalEstimatedSales = createSelector(
    (state: RootState) => selectPartners(state),
    (partners) => {
        const headroom = partners
            .map(partner => partner.sales.estimatedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(headroom);
    }
);

export const selectClientSourcedSales = createSelector(
    (state: RootState) => selectPartners(state),
    (partners) => {
        const clientSourcedSales = partners
            .map(partner => partner.sales.clientSourcedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(clientSourcedSales);
    }
);

export const selectIsPartnersFilterModified = createSelector(
    selectPartnersFilter,
    (partnersFilter) => {
        return partnersFilter.headroom[0] !== initialState.partnersFilter.headroom[0]
            || partnersFilter.headroom[1] !== initialState.partnersFilter.headroom[1]
            || partnersFilter.optimisedSales[0] !== initialState.partnersFilter.optimisedSales[0]
            || partnersFilter.optimisedSales[1] !== initialState.partnersFilter.optimisedSales[1]
            || partnersFilter.totalEstimatedSales[0] !== initialState.partnersFilter.totalEstimatedSales[0]
            || partnersFilter.totalEstimatedSales[1] !== initialState.partnersFilter.totalEstimatedSales[1]
            || partnersFilter.clientSourcedSales[0] !== initialState.partnersFilter.clientSourcedSales[0]
            || partnersFilter.clientSourcedSales[1] !== initialState.partnersFilter.clientSourcedSales[1];
    }
);

export const selectFilteredPartners = createSelector(
    (state: RootState) => selectPartners(state),
    selectPartnersSearch,
    selectPartnersFilter,
    selectPartnersSort,
    (partners, search, filter, sort) => {
        const searchString = search.name.toLowerCase();
        const filteredPartners = partners.filter(partner =>
            (!searchString || partner.name.toLowerCase().includes(searchString))
            && partner.sales.salesHeadroom >= filter.headroom[0]
            && partner.sales.salesHeadroom <= filter.headroom[1]
            && partner.sales.optimisedSales >= filter.optimisedSales[0]
            && partner.sales.optimisedSales <= filter.optimisedSales[1]
            && partner.sales.estimatedSales >= filter.totalEstimatedSales[0]
            && partner.sales.estimatedSales <= filter.totalEstimatedSales[1]
            && partner.sales.clientSourcedSales >= filter.clientSourcedSales[0]
            && partner.sales.clientSourcedSales <= filter.clientSourcedSales[1] 
        );

        return filteredPartners.sort((a, b) => {
            switch (sort.field) {
                case PartnersSortField.Headroom:
                    return numberSortExpression(a.sales.salesHeadroom, b.sales.salesHeadroom, sort.direction);
                case PartnersSortField.OptimisedSales:
                    return numberSortExpression(a.sales.optimisedSales, b.sales.optimisedSales, sort.direction);
                case PartnersSortField.TotalEstimatedSales:
                    return numberSortExpression(a.sales.estimatedSales, b.sales.estimatedSales, sort.direction);
                case PartnersSortField.ClientSourcedSales:
                    return numberSortExpression(a.sales.clientSourcedSales, b.sales.clientSourcedSales, sort.direction);
                case PartnersSortField.PartnerName:
                default:
                    return stringSortExpression(a.name, b.name, sort.direction);
            }
        });
    }
);

export default partnerFiltersSlice;
