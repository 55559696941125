import React from "react";

import { selectSelectedPartner } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";
import { Box, Typography } from "@material-ui/core";

const TotalSalesByStoreTitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    return (
        <Box paddingTop={1}>
            <Typography variant="h6">{selectedPartner?.name} optimised and estimated sales by store</Typography>
        </Box>
    );
};

export default TotalSalesByStoreTitle;
