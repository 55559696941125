import React from "react";

import { Grid } from "@material-ui/core";

import DistanceToCoreRetailHub from "./DistanceToCoreRetailHub";
import Map from "./Map";
import NumberOfCompetitors from "./NumberOfCompetitors";
import NumberOfDirectCompetitors from "./NumberOfDirectCompetitors";

const CompetitionAlignment: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
                <DistanceToCoreRetailHub />
            </Grid>
            <Grid item md={4} xs={12}>
                <NumberOfDirectCompetitors />
            </Grid>
            <Grid item md={4} xs={12}>
                <NumberOfCompetitors />
            </Grid>
            <Grid item xs={12}>
                <Map />
            </Grid>
        </Grid>
    );
};

export default CompetitionAlignment;
