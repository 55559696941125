import React from "react";
import SelectedStoreSales from "./SelectedStoreSales";
import Summary from "./Summary";
import { Grid } from "@material-ui/core";
import StoreHistoricSales from "./StoreHistoricSales";
import StoreSalesTitle from "./StoreSalesTitle";

const StoreInsight: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <Summary />
            </Grid>
            <Grid item md={6} xs={12}>
                <SelectedStoreSales />
            </Grid>
            <Grid item md={12}>
                <StoreSalesTitle />
            </Grid>
            <Grid item md={12}>
                <StoreHistoricSales />
            </Grid>
        </Grid>
    );
};

export default StoreInsight;
