import React from "react";
import StoreSalesCategoryMix from "./StoreSalesCategoryMix";
import EstimatedSalesMix from "./EstimatedSalesMix";
import { Box, Grid } from "@material-ui/core";
import OptimisedSalesMix from "./OptimisedSalesMix";
import SalesByProduct from "./SalesByProduct";
import SalesByProductTitle from "./SalesByProductTitle";
import ProductCategoryFilter from "./ProductCategoryFilter";
import SalesMixTitle from "./SalesMixTitle";
import PercentageSalesMixTitle from "./PercentageSalesMixTitle";

const ProductMix: React.FC = () => {
    return (
        <Grid container spacing={3} >
            <Grid item md={12}>
                <SalesMixTitle />
            </Grid>
            <Grid item md={12}>
                <ProductCategoryFilter />
            </Grid>
            <Grid item md={12}>
                <StoreSalesCategoryMix />
            </Grid>
            <Grid item md={12}>
                <PercentageSalesMixTitle />
            </Grid>
            <Grid item md={12}>
                <EstimatedSalesMix />
            </Grid>
            <Grid item md={12}>
                <OptimisedSalesMix />
            </Grid>
            <Grid item md={12}>
                <SalesByProductTitle />
            </Grid>
            <Grid item md={12}>
                <Box display="flex" flexDirection="column" >
                    <Box flexGrow={1} width='100%' height={'60vh'} >
                        <SalesByProduct />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProductMix;
