import React from "react";

import { useAppSelector } from "store";
import { selectProductsTotalSales, selectSelectedPartner } from "modules/customer/tools/product/productSlice";

import { Box } from "@material-ui/core";
import KPIFact from "components/visuals/KPIFact";
import numberFormatter from "utils/numberFormatter";

const HeadroomPastTwelveMonths: React.FC = () => {
    const productTotalSales = useAppSelector(selectProductsTotalSales);
    const partner = useAppSelector(selectSelectedPartner);

    return(
        <Box width="100%" height="100%" data-cy="range-overview">
            <KPIFact
                loading={productTotalSales.isLoading}
                error={productTotalSales.hasErrors}
                label={`${partner?.name} headroom in the past 12 months`}
                value={numberFormatter.toGBP(productTotalSales.data.totalSalesHeadroom, 1)} />
        </Box>
    );
};

export default HeadroomPastTwelveMonths;
