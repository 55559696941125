import React from "react";
import StoresTotalSales from "./StoresTotalSales";
import TotalSalesByStore from "./TotalSalesByStore";
import { Box, Grid } from "@material-ui/core";
import TotalSalesByStoreTitle from "./TotalSalesByStoreTitle";

const StoreOverview: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StoresTotalSales />
            </Grid>
            <Grid item xs={12}>
                <TotalSalesByStoreTitle />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" >
                    <Box flexGrow={1} width='100%' height={'60vh'} >
                        <TotalSalesByStore />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );

};

export default StoreOverview;
