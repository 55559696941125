import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectStores, selectProducts, selectReferenceDate, selectSelectedPartner, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { loadStoreMonthlySales, RangeMonthlySales } from "./rangeMonthlySales";
import { loadNumberStoresSellingRange } from "./numberOfStoresSellingRange";
import { DataWrapper } from "domain/dataWrapper";
import mathUtils from "utils/mathUtils";
import { loadCategoryTotalSales } from "./categoryTotalSales";

interface RangeOpportunitiesState {
    isLoading: boolean,
    hasErrors: boolean,
    rangeMonthlySales: RangeMonthlySales[],
    numberOfStoresSellingRange: number | null,
    categoryTotalSales: number | null
}

const initialState: RangeOpportunitiesState = {
    isLoading: false,
    hasErrors: false,
    rangeMonthlySales: [],
    numberOfStoresSellingRange: null,
    categoryTotalSales: null
};

interface LoadRangeOpportunitiesResponse {
    rangeMonthlySales: RangeMonthlySales[],
    numberOfStoresSellingRange: number,
    categoryTotalSales: number
}

const rangeOpportunitiesSlice = createSlice({
    name: "customer/tools/product/rangeOpportunities",
    initialState,
    reducers: {
        clearRangeMonthlySales: (state) => {
            state.rangeMonthlySales = initialState.rangeMonthlySales;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadRangeOpportunities.pending, (state: RangeOpportunitiesState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.rangeMonthlySales = initialState.rangeMonthlySales;
            state.numberOfStoresSellingRange = initialState.numberOfStoresSellingRange;
            state.categoryTotalSales = initialState.categoryTotalSales;
        });
        builder.addCase(loadRangeOpportunities.rejected, (state: RangeOpportunitiesState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.rangeMonthlySales = initialState.rangeMonthlySales;
            state.numberOfStoresSellingRange = initialState.numberOfStoresSellingRange;
            state.categoryTotalSales = initialState.categoryTotalSales;
        });
        builder.addCase(loadRangeOpportunities.fulfilled, (state: RangeOpportunitiesState, action: PayloadAction<LoadRangeOpportunitiesResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.rangeMonthlySales = action.payload.rangeMonthlySales;
            state.numberOfStoresSellingRange = action.payload.numberOfStoresSellingRange;
            state.categoryTotalSales = action.payload.categoryTotalSales;
        });
    }
});

export const loadRangeOpportunities = createAppAsyncThunk(
    "customer/tools/product/store/loadRangeOpportunities",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const referenceDate = selectReferenceDate(state);
            const selectedPartner = selectSelectedPartner(state);
            const selectedStore = selectSelectedStore(state);
            const selectedRange = selectSelectedRange(state);

            const monthlySalesPromise = thunkAPI.dispatch(loadStoreMonthlySales(referenceDate, selectedPartner, selectedRange, selectedStore?.id));
            const numberOfStoresSellingRangePromise = thunkAPI.dispatch(loadNumberStoresSellingRange(referenceDate, selectedPartner, selectedRange, selectedStore?.id));
            const categoryTotalSalesPromise = thunkAPI.dispatch(loadCategoryTotalSales(referenceDate, selectedPartner, selectedRange, selectedStore?.id));

            const results = await Promise.all([monthlySalesPromise, numberOfStoresSellingRangePromise, categoryTotalSalesPromise]);
            const loadRangeOpportunitiesResponse: LoadRangeOpportunitiesResponse = {
                rangeMonthlySales: results[0],
                numberOfStoresSellingRange: results[1],
                categoryTotalSales: results[2]
            };
            return loadRangeOpportunitiesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Store Opportunities.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

// export const {

// } = rangeOpportunitiesSlice.actions;

export const clearRangeOpportunities = (): AppThunk => async (dispatch) => {
    dispatch(rangeOpportunitiesSlice.actions.clearRangeMonthlySales());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.rangeOpportunities.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.rangeOpportunities.hasErrors;
};

export const selectRangeMonthlySales = (state: RootState) => {
    return state.customer.tools.product.rangeOpportunities.rangeMonthlySales;
};

export const selectNumberOfStoresSellingRange = (state: RootState) => {
    return state.customer.tools.product.rangeOpportunities.numberOfStoresSellingRange;
};

export const selectCategoryTotalSales = (state: RootState) => {
    return state.customer.tools.product.rangeOpportunities.categoryTotalSales;
};

export const selectNumberOfStoresSellingRangeFraction = createSelector(
    (state: RootState) => selectStores(state),
    (state: RootState) => selectNumberOfStoresSellingRange(state),
    (state: RootState) => selectIsLoading(state),
    (state: RootState) => selectHasErrors(state),
    (allStores, numberOfStoresSellingRange, isLoading, hasErrors) => {

        const numberOfStoresSellingRangeFraction: DataWrapper<{ numberOfStoresSellingRange: number, totalStoreCount: number }> = {
            isLoading: isLoading || allStores.isLoading,
            hasErrors: hasErrors || allStores.hasErrors,
            data: {
                numberOfStoresSellingRange: numberOfStoresSellingRange ?? 0,
                totalStoreCount: allStores.data.length
            }
        };

        return numberOfStoresSellingRangeFraction;
    }
);

export const selectRangeShareOfCategorySales = createSelector(
    (state: RootState) => selectSelectedRange(state),
    (state: RootState) => selectProducts(state),
    (state: RootState) => selectCategoryTotalSales(state),
    (state: RootState) => selectIsLoading(state),
    (state: RootState) => selectHasErrors(state),
    (selectedRange, products, categoryTotalSales, isLoading, hasErrors) => {
        const selectedRangeSales = products.data.find(product => product.id === selectedRange?.id)?.sales.estimatedSales ?? 0;
        const percentageShareOfCategorySales = mathUtils.safePercentage(selectedRangeSales, categoryTotalSales ?? 0);

        return {
            isLoading: isLoading || products.isLoading,
            hasErrors: hasErrors || products.hasErrors,
            data: percentageShareOfCategorySales
        };
    }
);

export default rangeOpportunitiesSlice;
