import React from "react";
import { Box, Grid } from "@material-ui/core";

import NumberOfStoresSellingSelectedRange from "./NumberOfStoresSellingSelectedRange";
import PercentageShareOfCategorySales from "./PercentageShareOfCategorySales";
import SalesByStore from "./SalesByStore";
import TotalSalesByStoreTitle from "./TotalSalesByStoreTitle";

const Store: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
                <NumberOfStoresSellingSelectedRange />
            </Grid>
            <Grid item md={6} xs={12}>
                <PercentageShareOfCategorySales />
            </Grid>
            <Grid item xs={12}>
                <TotalSalesByStoreTitle />
            </Grid>
            <Grid item md={12} >
                <Box display="flex" flexDirection="column" >
                    <Box flexGrow={1} width='100%' height={'60vh'} >
                        <SalesByStore />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Store;
