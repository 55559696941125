import React from "react";
import { Marker as MapGLMarker } from "react-map-gl";

import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import { useTheme } from "@material-ui/core/styles";

import { selectCoreRetailHub } from "modules/customer/tools/product/productStoreFit/productStoreFitSlice";
import { useAppSelector } from "store";

const CoreRetailHub: React.FC = () => {
    const theme = useTheme();
    const coreRetailHub = useAppSelector(selectCoreRetailHub);

    if (!coreRetailHub) {
        return null;
    }

    return (
        <MapGLMarker
            latitude={coreRetailHub.latitude}
            longitude={coreRetailHub.longitude}
            offsetTop={-10}
            offsetLeft={-12}
        >
            <RadioButtonCheckedRoundedIcon fontSize="small" style={{ color: theme.palette.common.white }} />
        </MapGLMarker>
    );
};

export default CoreRetailHub;
