import { selectSelectedPartner, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import React from "react";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);
    const selectedStore = useAppSelector(selectSelectedStore);
    return (
        <>
            Understand customer flow patterns at the {selectedPartner?.name} {selectedStore?.name} store. Explore peak and
            quieter periods to gauge how well {selectedRange?.name} aligns with traffic levels throughout the week.
        </>
    );
};

export default Subtitle;
