import React from "react";

import RangeOpportunities from "./rangeOpportunities/RangeOpportunities";
import RangeOverview from "./rangeOverview/RangeOverview";
import ProductStoreFit from "./productStoreFit/ProductStoreFit";
import StoreOpportunities from "./storeOpportunities/StoreOpportunities";
import StoreOverview from "./storeOverview/StoreOverview";

import { ProductChapter, selectCurrentChapter } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";

interface InsightProps {
    isSetupComplete: boolean
}

const Insight: React.FC<InsightProps> = (props) => {
    const { isSetupComplete } = props;
    const currentChapter = useAppSelector(selectCurrentChapter);

    return isSetupComplete ? (
        <>
            {currentChapter === ProductChapter.RangeOverview && (
                <RangeOverview />
            )}
            {currentChapter === ProductChapter.RangeOpportunities && (
                <RangeOpportunities />
            )}
            {currentChapter === ProductChapter.StoreOverview && (
                <StoreOverview />
            )}
            {currentChapter === ProductChapter.StoreOpportunities && (
                <StoreOpportunities />
            )}
            {currentChapter === ProductChapter.ProductStoreFit && (
                <ProductStoreFit />
            )}
        </>
    ) : (<></>);
};

export default Insight;
