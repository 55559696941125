import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { RootState } from "store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { loadRetailCentreMetrics, RetailCentreMetrics } from "./retailCentreMetrics";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { CatchmentCustomerProfile, loadCatchmentCustomerProfiles } from "./catchmentCustomerProfile";
import { CustomerProfileDefinition, loadCustomerProfileDefinitions } from "./customerProfileDefinition";
import _ from "lodash";
import { CoreRetailHub, loadCoreRetailHub } from "./coreRetailHub";
import { Competitor, loadCompetitors } from "./competitor";
import mathUtils from "utils/mathUtils";
import { AverageDailyFootfall, loadAverageDailyFootfall } from "./averageDailyFootfall";

interface ProductStoreFitState {
    isLoading: boolean,
    hasErrors: boolean,
    catchmentAlignmentIsLoading: boolean,
    catchmentAlignmentHasErrors: boolean,
    footfallAlignmentIsLoading: boolean,
    footfallAlignmentHasErrors: boolean,
    competitionAlignmentIsLoading: boolean,
    competitionAlignmentHasErrors: boolean,
    retailCentreMetrics?: RetailCentreMetrics,
    catchmentCustomerProfiles: CatchmentCustomerProfile[],
    customerProfileDefinitions: CustomerProfileDefinition[],
    averageDailyFootfall: AverageDailyFootfall[],
    coreRetailHub?: CoreRetailHub,
    competitors: Competitor[]
}

export interface CustomerProfilesTreemapRow {
    name: string,
    type: "Supergroup" | "Group" | "Subgroup",
    supergroupCode?: number | undefined,
    parent: string | undefined,
    numberOfVisitors: number,
    percentageOverallVisitors: number
}

const initialState: ProductStoreFitState = {
    isLoading: false,
    hasErrors: false,
    catchmentAlignmentIsLoading: false,
    catchmentAlignmentHasErrors: false,
    footfallAlignmentIsLoading: false,
    footfallAlignmentHasErrors: false,
    competitionAlignmentIsLoading: false,
    competitionAlignmentHasErrors: false,
    retailCentreMetrics: undefined,
    catchmentCustomerProfiles: [],
    customerProfileDefinitions: [],
    averageDailyFootfall: [],
    coreRetailHub: undefined,
    competitors: []
};

interface LoadCatchmentAlignmentResponse {
    retailCentreMetrics: RetailCentreMetrics,
    catchmentCustomerProfiles: CatchmentCustomerProfile[],
    customerProfileDefinitions: CustomerProfileDefinition[]
}

interface LoadFootfallAlignmentResponse {
    averageDailyFootfall: AverageDailyFootfall[]
}

interface LoadCompetitionAlignmentResponse {
    coreRetailHub?: CoreRetailHub,
    competitors: Competitor[]
}

const productStoreFitSlice = createSlice({
    name: "customer/tools/product/productStoreFit",
    initialState,
    reducers: {
        clearRetailCentreMetrics: (state) => {
            state.retailCentreMetrics = initialState.retailCentreMetrics;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadProductStoreFit.pending, (state: ProductStoreFitState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadProductStoreFit.rejected, (state: ProductStoreFitState) => {
            state.isLoading = false;
            state.hasErrors = true;
        });
        builder.addCase(loadProductStoreFit.fulfilled, (state: ProductStoreFitState) => {
            state.isLoading = false;
            state.hasErrors = false;
        });
        builder.addCase(loadCatchmentAlignment.pending, (state: ProductStoreFitState) => {
            state.catchmentAlignmentIsLoading = true;
            state.catchmentAlignmentHasErrors = false;
            state.retailCentreMetrics = initialState.retailCentreMetrics;
            state.catchmentCustomerProfiles = initialState.catchmentCustomerProfiles;
            state.customerProfileDefinitions = initialState.customerProfileDefinitions;
        });
        builder.addCase(loadCatchmentAlignment.rejected, (state: ProductStoreFitState) => {
            state.catchmentAlignmentIsLoading = false;
            state.catchmentAlignmentHasErrors = true;
            state.retailCentreMetrics = initialState.retailCentreMetrics;
            state.catchmentCustomerProfiles = initialState.catchmentCustomerProfiles;
            state.customerProfileDefinitions = initialState.customerProfileDefinitions;
        });
        builder.addCase(loadCatchmentAlignment.fulfilled, (state: ProductStoreFitState, action: PayloadAction<LoadCatchmentAlignmentResponse>) => {
            state.catchmentAlignmentIsLoading = false;
            state.catchmentAlignmentHasErrors = false;
            state.retailCentreMetrics = action.payload.retailCentreMetrics;
            state.catchmentCustomerProfiles = action.payload.catchmentCustomerProfiles;
            state.customerProfileDefinitions = action.payload.customerProfileDefinitions;
        });
        builder.addCase(loadFootfallAlignment.pending, (state: ProductStoreFitState) => {
            state.competitionAlignmentIsLoading = true;
            state.competitionAlignmentHasErrors = false;
            state.averageDailyFootfall = initialState.averageDailyFootfall;
        });
        builder.addCase(loadFootfallAlignment.rejected, (state: ProductStoreFitState) => {
            state.competitionAlignmentIsLoading = false;
            state.competitionAlignmentHasErrors = true;
            state.averageDailyFootfall = initialState.averageDailyFootfall;
        });
        builder.addCase(loadFootfallAlignment.fulfilled, (state: ProductStoreFitState, action: PayloadAction<LoadFootfallAlignmentResponse>) => {
            state.competitionAlignmentIsLoading = false;
            state.competitionAlignmentHasErrors = false;
            state.averageDailyFootfall = action.payload.averageDailyFootfall;
        });
        builder.addCase(loadCompetitionAlignment.pending, (state: ProductStoreFitState) => {
            state.competitionAlignmentIsLoading = true;
            state.competitionAlignmentHasErrors = false;
            state.coreRetailHub = initialState.coreRetailHub;
            state.competitors = initialState.competitors;
        });
        builder.addCase(loadCompetitionAlignment.rejected, (state: ProductStoreFitState) => {
            state.competitionAlignmentIsLoading = false;
            state.competitionAlignmentHasErrors = true;
            state.coreRetailHub = initialState.coreRetailHub;
            state.competitors = initialState.competitors;
        });
        builder.addCase(loadCompetitionAlignment.fulfilled, (state: ProductStoreFitState, action: PayloadAction<LoadCompetitionAlignmentResponse>) => {
            state.competitionAlignmentIsLoading = false;
            state.competitionAlignmentHasErrors = false;
            state.coreRetailHub = action.payload.coreRetailHub;
            state.competitors = action.payload.competitors;
        });
    }
});

export const loadProductStoreFit = createAppAsyncThunk(
    "customer/tools/product/store/loadProductStoreFit",
    async (arg, thunkAPI) => {
        try {
            thunkAPI.dispatch(loadCatchmentAlignment());
            thunkAPI.dispatch(loadFootfallAlignment());
            thunkAPI.dispatch(loadCompetitionAlignment());
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Product Store Fit.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

const loadCatchmentAlignment = createAppAsyncThunk(
    "customer/tools/product/store/loadCatchmentAlignment",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const userInfo = selectUserInfo(state);
            const selectedStore = selectSelectedStore(state);

            const catchmentCustomerProfilesPromise = thunkAPI.dispatch(loadCatchmentCustomerProfiles(userInfo.accountId, selectedStore));
            const retailCentreMetricsPromise = thunkAPI.dispatch(loadRetailCentreMetrics(
                userInfo.accountId,
                selectedStore?.retailCentreID,
                selectedStore?.storeCategoryID
            ));
            const customerProfileDefinitionsPromise = thunkAPI.dispatch(loadCustomerProfileDefinitions());
            const results = await Promise.all([retailCentreMetricsPromise, catchmentCustomerProfilesPromise, customerProfileDefinitionsPromise]);

            const loadCatchmentAlignmentResponse: LoadCatchmentAlignmentResponse = {
                retailCentreMetrics: results[0],
                catchmentCustomerProfiles: results[1],
                customerProfileDefinitions: results[2]
            };
            return loadCatchmentAlignmentResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Catchment Alignment.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

const loadFootfallAlignment = createAppAsyncThunk(
    "customer/tools/product/store/loadFootfallAlignment",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const selectedStore = selectSelectedStore(state);

            const averageDailyFootfall = await thunkAPI.dispatch(loadAverageDailyFootfall(selectedStore));
            const loadFootfallAlignmentResponse: LoadFootfallAlignmentResponse = {
                averageDailyFootfall
            };
            return loadFootfallAlignmentResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Footfall Alignment.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

const loadCompetitionAlignment = createAppAsyncThunk(
    "customer/tools/product/store/loadCompetitionAlignment",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const store = selectSelectedStore(state);

            const coreRetailHubPromise = thunkAPI.dispatch(loadCoreRetailHub(store));
            const competitorsPromise = thunkAPI.dispatch(loadCompetitors(store));
            const results = await Promise.all([coreRetailHubPromise, competitorsPromise]);

            const loadCompetitionAlignmentResponse: LoadCompetitionAlignmentResponse = {
                coreRetailHub: results[0],
                competitors: results[1]
            };
            return loadCompetitionAlignmentResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Competition Alignment.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

// export const {

// } = productStoreFitSlice.actions;

export const clearProductStoreFit = (): AppThunk => async (dispatch) => {
    dispatch(productStoreFitSlice.actions.clearRetailCentreMetrics());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.hasErrors;
};

export const selectCatchmentAlignmentIsLoading = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.catchmentAlignmentIsLoading;
};

export const selectCatchmentAlignmentHasErrors = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.catchmentAlignmentHasErrors;
};

export const selectFootfallAlignmentIsLoading = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.footfallAlignmentIsLoading;
};

export const selectFootfallAlignmentHasErrors = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.footfallAlignmentHasErrors;
};

export const selectCompetitionAlignmentIsLoading = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.competitionAlignmentIsLoading;
};

export const selectCompetitionAlignmentHasErrors = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.competitionAlignmentHasErrors;
};

export const selectRetailCentreMetrics = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.retailCentreMetrics;
};

export const selectCatchmentCustomerProfiles = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.catchmentCustomerProfiles;
};

export const selectCustomerProfileDefinitions = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.customerProfileDefinitions;
};

export const selectAverageDailyFootfall = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.averageDailyFootfall;
};

export const selectCoreRetailHub = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.coreRetailHub;
};

export const selectCompetitors = (state: RootState) => {
    return state.customer.tools.product.productStoreFit.competitors;
};

export const selectCustomerProfileBreakdown = createSelector(
    selectCatchmentCustomerProfiles,
    (customerProfiles) => {
        let customerProfileBreakdown: CustomerProfilesTreemapRow[] = [];
        if (customerProfiles.length === 0) {
            return customerProfileBreakdown;
        }

        const totalPopulation = customerProfiles.reduce((total, current) => total + current.numberOfVisitors, 0);

        customerProfileBreakdown = _(customerProfiles)
            .groupBy(item => item.supergroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.numberOfVisitors, 0);
                const aggregatedSupergroup: CustomerProfilesTreemapRow = {
                    name,
                    supergroupCode: group[0].supergroupCode,
                    type: "Supergroup",
                    parent: undefined,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedSupergroup;
            }).value();

        customerProfileBreakdown = customerProfileBreakdown.concat(_(customerProfiles)
            .groupBy(item => item.groupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.numberOfVisitors, 0);
                const aggregatedGroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Group",
                    parent: group[0].supergroupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedGroup;
            }).value()
        );

        customerProfileBreakdown = customerProfileBreakdown.concat(_(customerProfiles)
            .groupBy(item => item.subgroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.numberOfVisitors, 0);
                const aggregatedSubgroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Subgroup",
                    parent: group[0].groupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedSubgroup;
            }).value()
        );
        return customerProfileBreakdown;

    }
);

export const selectDistanceToCoreRetailHub = createSelector(
    selectCompetitionAlignmentIsLoading,
    selectCompetitionAlignmentHasErrors,
    (state: RootState) => selectSelectedStore(state),
    selectCoreRetailHub,
    (isLoading, hasErrors, store, coreRetailHub) => {
        interface DistanceToCoreRetailHub {
            value: number
        }

        const distanceToCoreRetailHub: DataWrapper<DistanceToCoreRetailHub> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: { value: 0 }
        };

        if (isLoading || hasErrors || !store || !coreRetailHub) {
            return distanceToCoreRetailHub;
        }

        distanceToCoreRetailHub.data.value = mathUtils.haversineDistance(store.latitude, store.longitude, coreRetailHub.latitude, coreRetailHub.longitude);
        return distanceToCoreRetailHub;
    }
);

export const selectCompetitorsWithin5Kms = createSelector(
    selectCompetitionAlignmentIsLoading,
    selectCompetitionAlignmentHasErrors,
    (state: RootState) => selectSelectedStore(state),
    selectCompetitors,
    (isLoading, hasErrors, store, competitors) => {
        const competitorsWithin5Kms: Competitor[] = [];

        if (isLoading || hasErrors || !store) {
            return competitorsWithin5Kms;
        }

        competitors.forEach(competitor => {
            const distance = mathUtils.haversineDistance(store.latitude, store.longitude, competitor.latitude, competitor.longitude);
            if (distance <= 5) {
                competitorsWithin5Kms.push(competitor);
            }
        });
        return competitorsWithin5Kms;
    }
);

export const selectNumberOfCompetitors = createSelector(
    selectCompetitionAlignmentIsLoading,
    selectCompetitionAlignmentHasErrors,
    selectCompetitorsWithin5Kms,
    (isLoading, hasErrors, competitorsWithin5Kms) => {
        interface NumberOfCompetitors {
            all: number,
            direct: number
        }

        const numberOfCompetitors: DataWrapper<NumberOfCompetitors> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: { all: 0, direct: 0 }
        };

        if (isLoading || hasErrors) {
            return numberOfCompetitors;
        }

        numberOfCompetitors.data.all = competitorsWithin5Kms.length;
        numberOfCompetitors.data.direct = competitorsWithin5Kms.filter(competitor => competitor.directCompetitor).length;
        return numberOfCompetitors;
    }
);

export default productStoreFitSlice;
