import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, Slider, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { selectUserInfo } from "modules/auth/authSlice";
import {
    selectStoresHeadroom,
    selectStoresOptimisedSales,
    selectStoresTotalEstimatedSales,
    selectStoresClientSourcedSales,
    selectStoresFilter,
    selectIsStoresFilterModified,
    setStoresFilter,
    clearStoresFilter,
    StoresFilter
} from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    paper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    list: {
        width: theme.spacing(35)
    }
}))(Menu);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const headroom = useAppSelector(selectStoresHeadroom);
    const optimisedSales = useAppSelector(selectStoresOptimisedSales);
    const totalEstimatedSales = useAppSelector(selectStoresTotalEstimatedSales);
    const clientSourcedSales = useAppSelector(selectStoresClientSourcedSales);
    const storesFilter = useAppSelector(selectStoresFilter);
    const isStoresFilterModified = useAppSelector(selectIsStoresFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [/*currentStoresFilter*/, setCurrentStoresFilter] = React.useState<StoresFilter>(storesFilter);

    // Headroom slider values
    const headroomPercentileThresholds = headroom.percentileThresholds;
    const headroomSliderMinValue = headroomPercentileThresholds.indexOf(Math.max(headroom.minPercentileThreshold, storesFilter.headroom[0]));
    const headroomSliderMaxValue = headroomPercentileThresholds.indexOf(Math.min(headroom.maxPercentileThreshold, storesFilter.headroom[1]));
    const headroomSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < headroomPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(headroomPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [headroomPercentileThresholds]);

    // Optimised sales slider values
    const optimisedSalesPercentileThresholds = optimisedSales.percentileThresholds;
    const optimisedSalesSliderMinValue = optimisedSalesPercentileThresholds.indexOf(Math.max(optimisedSales.minPercentileThreshold, storesFilter.optimisedSales[0]));
    const optimisedSalesSliderMaxValue = optimisedSalesPercentileThresholds.indexOf(Math.min(optimisedSales.maxPercentileThreshold, storesFilter.optimisedSales[1]));
    const optimisedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < optimisedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(optimisedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [optimisedSalesPercentileThresholds]);

    // Total estimated sales slider values
    const totalEstimatedSalesPercentileThresholds = totalEstimatedSales.percentileThresholds;
    const totalEstimatedSalesSliderMinValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.max(totalEstimatedSales.minPercentileThreshold, storesFilter.totalEstimatedSales[0]));
    const totalEstimatedSalesSliderMaxValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.min(totalEstimatedSales.maxPercentileThreshold, storesFilter.totalEstimatedSales[1]));
    const totalEstimatedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < totalEstimatedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(totalEstimatedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [totalEstimatedSalesPercentileThresholds]);

    // Client sourced sales slider values
    const clientSourcedSalesPercentileThresholds = clientSourcedSales.percentileThresholds;
    const clientSourcedSalesSliderMinValue = clientSourcedSalesPercentileThresholds.indexOf(Math.max(clientSourcedSales.minPercentileThreshold, storesFilter.clientSourcedSales[0]));
    const clientSourcedSalesSliderMaxValue = clientSourcedSalesPercentileThresholds.indexOf(Math.min(clientSourcedSales.maxPercentileThreshold, storesFilter.clientSourcedSales[1]));
    const clientSourcedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < clientSourcedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(clientSourcedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [clientSourcedSalesPercentileThresholds]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearStoresFilter());
    };

    const setStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((storesFilter: StoresFilter) => dispatch(setStoresFilter(storesFilter)), 400);
    }, [dispatch]);

    const handleHeadroomChange = (event: React.ChangeEvent<{}>, value: any) => {
        const headroom = [headroomPercentileThresholds[value[0]], headroomPercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, headroom };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleOptimisedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const optimisedSales = [optimisedSalesPercentileThresholds[value[0]], optimisedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...storesFilter, optimisedSales };
        setCurrentStoresFilter(newPartnersFilter);
        setStoresFilterDelayed(newPartnersFilter);
    };

    const handleTotalEstimatedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const totalEstimatedSales = [totalEstimatedSalesPercentileThresholds[value[0]], totalEstimatedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...storesFilter, totalEstimatedSales };
        setCurrentStoresFilter(newPartnersFilter);
        setStoresFilterDelayed(newPartnersFilter);
    };

    const handleClientSourcedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const clientSourcedSales = [clientSourcedSalesPercentileThresholds[value[0]], clientSourcedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...storesFilter, clientSourcedSales };
        setCurrentStoresFilter(newPartnersFilter);
        setStoresFilterDelayed(newPartnersFilter);
    };

    React.useEffect(() => {
        setCurrentStoresFilter(storesFilter);
    }, [storesFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Headroom
                        </Typography>
                        <Slider
                            value={[headroomSliderMinValue, headroomSliderMaxValue]}
                            onChange={handleHeadroomChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={headroomSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Optimised sales
                        </Typography>
                        <Slider
                            value={[optimisedSalesSliderMinValue, optimisedSalesSliderMaxValue]}
                            onChange={handleOptimisedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={optimisedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Total estimated sales
                        </Typography>
                        <Slider
                            value={[totalEstimatedSalesSliderMinValue, totalEstimatedSalesSliderMaxValue]}
                            onChange={handleTotalEstimatedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={totalEstimatedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            {clientName} sourced sales
                        </Typography>
                        <Slider
                            value={[clientSourcedSalesSliderMinValue, clientSourcedSalesSliderMaxValue]}
                            onChange={handleClientSourcedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={clientSourcedSalesSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
