import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { selectUserInfo } from "modules/auth/authSlice";
import {
    clearStoresFilter,
    selectIsStoresFilterModified,
    selectRevenue,
    selectStoreSizes,
    selectClientRegions,
    selectRegions,
    selectCurrentStoresCategories,
    selectPitchTypes,
    selectGroups,
    selectFormatTypes,
    selectSegments,
    selectFirstAndLatestStoreOpeningDate,
    selectStoresFilter,
    setStoresFilter,
    StoresFilter
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    paper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    list: {
        width: theme.spacing(35)
    }
}))(Menu);

const StyledKeyboardDatePicker = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main,
            color: theme.palette.common.white,
            marginTop: theme.spacing(-2)
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: theme.spacing(0)
        },
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent"
        },
        "& .MuiFormHelperText-contained": {
            margin: theme.spacing(0)
        }
    },
}))(KeyboardDatePicker);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const revenue = useAppSelector(selectRevenue);
    const storeSizes = useAppSelector(selectStoreSizes);
    const clientRegions = useAppSelector(selectClientRegions);
    const regions = useAppSelector(selectRegions);
    const storeCategories = useAppSelector(selectCurrentStoresCategories);
    const pitchTypes = useAppSelector(selectPitchTypes);
    const groups = useAppSelector(selectGroups);
    const formatTypes = useAppSelector(selectFormatTypes);
    const segments = useAppSelector(selectSegments);
    const openingDates = useAppSelector(selectFirstAndLatestStoreOpeningDate);
    const storesFilter = useAppSelector(selectStoresFilter);
    const isStoresFilterModified = useAppSelector(selectIsStoresFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentStoresFilter, setCurrentStoresFilter] = React.useState<StoresFilter>(storesFilter);

    // Revenue slider values
    const revenuePercentileThresholds = revenue.percentileThresholds;
    const revenueSliderMinValue = revenuePercentileThresholds.indexOf(Math.max(revenue.minPercentileThreshold, storesFilter.revenue[0]));
    const revenueSliderMaxValue = revenuePercentileThresholds.indexOf(Math.min(revenue.maxPercentileThreshold, storesFilter.revenue[1]));
    const revenueSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < revenuePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(revenuePercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [revenuePercentileThresholds]);

    // Store sizes slider values
    const storeSizePercentileThresholds = storeSizes.percentileThresholds;
    const storeSizeSliderMinValue = storeSizePercentileThresholds.indexOf(Math.max(storeSizes.minPercentileThreshold, storesFilter.storeSize[0]));
    const storeSizeSliderMaxValue = storeSizePercentileThresholds.indexOf(Math.min(storeSizes.maxPercentileThreshold, storesFilter.storeSize[1]));
    const storeSizeSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < storeSizePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(storeSizePercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [storeSizePercentileThresholds]);

    // Affluence slider values
    const affluenceSliderMinValue = Math.max(0, storesFilter.affluence[0]);
    const affluenceSliderMaxValue = Math.min(100, storesFilter.affluence[1]);
    const affluenceSliderStep = (100 - 0) / 5;
    const affluenceSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * affluenceSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [affluenceSliderStep]);

    // Age slider values
    const ageSliderMinValue = Math.max(0, storesFilter.age[0]);
    const ageSliderMaxValue = Math.min(100, storesFilter.age[1]);
    const ageSliderStep = (100 - 0) / 5;
    const ageSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * ageSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [ageSliderStep]);

    // Children slider values
    const childrenSliderMinValue = Math.max(0, storesFilter.children[0]);
    const childrenSliderMaxValue = Math.min(100, storesFilter.children[1]);
    const childrenSliderStep = (100 - 0) / 5;
    const childrenSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * childrenSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [childrenSliderStep]);

    // Diversity slider values
    const diversitySliderMinValue = Math.max(0, storesFilter.diversity[0]);
    const diversitySliderMaxValue = Math.min(100, storesFilter.diversity[1]);
    const diversitySliderStep = (100 - 0) / 5;
    const diversitySliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * diversitySliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [diversitySliderStep]);

    // Urbanicity slider values
    const urbanicitySliderMinValue = Math.max(0, storesFilter.urbanicity[0]);
    const urbanicitySliderMaxValue = Math.min(100, storesFilter.urbanicity[1]);
    const urbanicitySliderStep = (100 - 0) / 5;
    const urbanicitySliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * urbanicitySliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [urbanicitySliderStep]);

    // Area health slider values
    const areaHealthSliderMinValue = Math.max(0, storesFilter.areaHealth[0]);
    const areaHealthSliderMaxValue = Math.min(100, storesFilter.areaHealth[1]);
    const areaHealthSliderStep = (100 - 0) / 5;
    const areaHealthSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * areaHealthSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [areaHealthSliderStep]);

    // Footfall slider values
    const footfallSliderMinValue = Math.max(0, storesFilter.footfall[0]);
    const footfallSliderMaxValue = Math.min(100, storesFilter.footfall[1]);
    const footfallSliderStep = (100 - 0) / 5;
    const footfallSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * footfallSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [footfallSliderStep]);

    // Spend slider values
    const spendSliderMinValue = Math.max(0, storesFilter.spend[0]);
    const spendSliderMaxValue = Math.min(100, storesFilter.spend[1]);
    const spendSliderStep = (100 - 0) / 5;
    const spendSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < 6; i++) {
            const value = i * spendSliderStep;
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [spendSliderStep]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearStoresFilter());
    };

    const setStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((storesFilter: StoresFilter) => dispatch(setStoresFilter(storesFilter)), 400);
    }, [dispatch]);

    const handleRevenueChange = (event: React.ChangeEvent<{}>, value: any) => {
        const revenue = [revenuePercentileThresholds[value[0]], revenuePercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, revenue };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleStoreSizeChange = (event: React.ChangeEvent<{}>, value: any) => {
        const storeSize = [storeSizePercentileThresholds[value[0]], storeSizePercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, storeSize };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleClientRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const clientRegion = event.target.value;
        const newStoresFilter = { ...storesFilter, clientRegion };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const region = event.target.value;
        const newStoresFilter = { ...storesFilter, region };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleStoreCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const category = event.target.value;
        const newStoresFilter = { ...storesFilter, category };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handlePitchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pitch = event.target.value;
        const newStoresFilter = { ...storesFilter, pitch };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const group = event.target.value;
        const newStoresFilter = { ...storesFilter, group };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleFormatTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const format = event.target.value;
        const newStoresFilter = { ...storesFilter, format };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleSegmentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const segment = event.target.value;
        const newStoresFilter = { ...storesFilter, segment };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleStoreOpeningDateFromChange = (date: any) => {
        const openingDateFrom = date;
        const newStoresFilter = { ...storesFilter, openingDateFrom };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleAffluenceChange = (event: React.ChangeEvent<{}>, value: any) => {
        const affluence = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, affluence };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleAgeChange = (event: React.ChangeEvent<{}>, value: any) => {
        const age = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, age };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleChildrenChange = (event: React.ChangeEvent<{}>, value: any) => {
        const children = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, children };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleDiversityChange = (event: React.ChangeEvent<{}>, value: any) => {
        const diversity = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, diversity };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleUrbanicityChange = (event: React.ChangeEvent<{}>, value: any) => {
        const urbanicity = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, urbanicity };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleAreaHealthChange = (event: React.ChangeEvent<{}>, value: any) => {
        const areaHealth = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, areaHealth };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleFootfallChange = (event: React.ChangeEvent<{}>, value: any) => {
        const footfall = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, footfall };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleSpendChange = (event: React.ChangeEvent<{}>, value: any) => {
        const spend = [value[0], value[1]];
        const newStoresFilter = { ...storesFilter, spend };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    React.useEffect(() => {
        setCurrentStoresFilter(storesFilter);
    }, [storesFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Revenue
                        </Typography>
                        <Slider
                            value={[revenueSliderMinValue, revenueSliderMaxValue]}
                            onChange={handleRevenueChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={revenueSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Square footage
                        </Typography>
                        <Slider
                            value={[storeSizeSliderMinValue, storeSizeSliderMaxValue]}
                            onChange={handleStoreSizeChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={storeSizeSliderMarks}
                        />
                    </Box>
                    {(clientRegions.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            {userInfo.companyDisplayName} region
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.clientRegion}
                            onChange={handleClientRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {clientRegions.map((clientRegion, index) =>
                                <MenuItem key={`clientRegion_${index}`} value={clientRegion}>
                                    {clientRegion}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(regions.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Region
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.region}
                            onChange={handleRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {regions.map((region, index) =>
                                <MenuItem key={`region_${index}`} value={region}>
                                    {region}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(storeCategories.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Store category
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.category}
                            onChange={handleStoreCategoryChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select store category
                            </MenuItem>
                            {storeCategories.map((storeCategory, index) =>
                                <MenuItem key={`storeCategory_${index}`} value={storeCategory}>
                                    {storeCategory}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(pitchTypes.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Pitch type
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.pitch}
                            onChange={handlePitchTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select pith type
                            </MenuItem>
                            {pitchTypes.map((pitchType, index) =>
                                <MenuItem key={`pitchType_${index}`} value={pitchType}>
                                    {pitchType}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(groups.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Group
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.group}
                            onChange={handleGroupChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select group
                            </MenuItem>
                            {groups.map((group, index) =>
                                <MenuItem key={`group_${index}`} value={group}>
                                    {group}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(formatTypes.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Store format type
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.format}
                            onChange={handleFormatTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select format type
                            </MenuItem>
                            {formatTypes.map((format, index) =>
                                <MenuItem key={`format_${index}`} value={format}>
                                    {format}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    {(segments.length > 1) && <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Segment type
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.segment}
                            onChange={handleSegmentTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select segment type
                            </MenuItem>
                            {segments.map((segment, index) =>
                                <MenuItem key={`segment_${index}`} value={segment}>
                                    {segment}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>}
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Opening date from
                        </Typography>
                        <StyledKeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            color="primary"
                            margin="normal"
                            format="dd/MM/yyyy"
                            minDate={openingDates.firstOpeningDate}
                            minDateMessage={`Date should not be before ${openingDates.firstOpeningDate.toLocaleDateString()}`}
                            maxDate={openingDates.lastOpeningDate}
                            maxDateMessage={`Date should not be after ${openingDates.lastOpeningDate.toLocaleDateString()}`}
                            value={(!(currentStoresFilter.openingDateFrom) ? openingDates.firstOpeningDate : currentStoresFilter.openingDateFrom)}
                            onChange={handleStoreOpeningDateFromChange}
                            fullWidth
                            disableToolbar
                            disableFuture
                            autoOk
                            KeyboardButtonProps={{
                                disableRipple: true
                            }}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Affluence
                        </Typography>
                        <Slider
                            value={[affluenceSliderMinValue, affluenceSliderMaxValue]}
                            onChange={handleAffluenceChange}
                            step={affluenceSliderStep}
                            min={0}
                            max={100}
                            marks={affluenceSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Age
                        </Typography>
                        <Slider
                            value={[ageSliderMinValue, ageSliderMaxValue]}
                            onChange={handleAgeChange}
                            step={ageSliderStep}
                            min={0}
                            max={100}
                            marks={ageSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Children
                        </Typography>
                        <Slider
                            value={[childrenSliderMinValue, childrenSliderMaxValue]}
                            onChange={handleChildrenChange}
                            step={childrenSliderStep}
                            min={0}
                            max={100}
                            marks={childrenSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Diversity
                        </Typography>
                        <Slider
                            value={[diversitySliderMinValue, diversitySliderMaxValue]}
                            onChange={handleDiversityChange}
                            step={diversitySliderStep}
                            min={0}
                            max={100}
                            marks={diversitySliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Urbanicity
                        </Typography>
                        <Slider
                            value={[urbanicitySliderMinValue, urbanicitySliderMaxValue]}
                            onChange={handleUrbanicityChange}
                            step={urbanicitySliderStep}
                            min={0}
                            max={100}
                            marks={urbanicitySliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Area health
                        </Typography>
                        <Slider
                            value={[areaHealthSliderMinValue, areaHealthSliderMaxValue]}
                            onChange={handleAreaHealthChange}
                            step={areaHealthSliderStep}
                            min={0}
                            max={100}
                            marks={areaHealthSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Footfall
                        </Typography>
                        <Slider
                            value={[footfallSliderMinValue, footfallSliderMaxValue]}
                            onChange={handleFootfallChange}
                            step={footfallSliderStep}
                            min={0}
                            max={100}
                            marks={footfallSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Spend
                        </Typography>
                        <Slider
                            value={[spendSliderMinValue, spendSliderMaxValue]}
                            onChange={handleSpendChange}
                            step={spendSliderStep}
                            min={0}
                            max={100}
                            marks={spendSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
