import React from "react";
import { Box } from "@material-ui/core";

import { useAppSelector } from "store";

import KPIFact from "components/visuals/KPIFact";
import { selectRangeShareOfCategorySales } from "modules/customer/tools/product/rangeOpportunities/rangeOpportunitiesSlice";
import { selectSelectedRange } from "modules/customer/tools/product/productSlice";
import numberFormatter from "utils/numberFormatter";

const PercentageShareOfCategorySales = () => {
    const selectedRange = useAppSelector(selectSelectedRange);
    const percentageShareOfCategorySales = useAppSelector(selectRangeShareOfCategorySales);
    const { isLoading, hasErrors } = percentageShareOfCategorySales;
    const formattedValueString = numberFormatter.toPercentage(percentageShareOfCategorySales.data);
    const label = `${selectedRange?.name} share of all ${selectedRange?.productCategory} sales`;

    return (
        <Box width="100%" height="100%" data-cy="percentage-share-of-category-sales">
            <KPIFact
                loading={isLoading}
                error={hasErrors}
                label={label}
                value={formattedValueString} />
        </Box>
    );
};

export default PercentageShareOfCategorySales;
