import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import RangeOpportunities from "./rangeOpportunities/RangeOpportunities";
import RangeOverviewSubchapter from "./rangeOverview/RangeOverview";

import { useAppSelector } from "store";
import { selectSelectedPartner } from "modules/customer/tools/product/productSlice";

const RangeOverview: React.FC = () => {
    const partner = useAppSelector(selectSelectedPartner);
    return (
        <Chapter
            title="Range overview"
            subtitle={`Identify top-performing products and spot growth opportunities across ${partner?.name}'s estate to maximise sales potential.`}
            dataCy="product-overview-chapter"
        >
            <Subchapter
                title="Range overview"
                subtitle={`See how each product category is performing across ${partner?.name}'s estate.`}
                dataCy="range-overview-insight-subchapter"
            >
                <RangeOverviewSubchapter />
            </Subchapter>
            <Subchapter
                title="Range opportunities"
                subtitle="Spot the products with the highest potential for growth. Compare estimated and optimised sales to uncover where adjustments in store placement could unlock new sales opportunities."
                dataCy="range-opportunities-subchapter"
            >
                <RangeOpportunities />
            </Subchapter>
        </Chapter>
    );
};

export default RangeOverview;
