import { ResultSet } from "@cubejs-client/core";
import { immerable } from "immer";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadCostTypes = (): AppThunk<Promise<CostType[]>> => async (dispatch) => {
    try {
        const query = {
            filters: [{
                "member": "F_Cost.SumCostValue",
                "operator": "set",
            }],
            dimensions: ["D_Account.AccountName", "F_Cost.FK_AccountLine"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        return resultSet.rawData().map(row => new CostType(
            row["D_Account.AccountName"],
            row["F_Cost.FK_AccountLine"]
        ));
    } catch (error) {
        dispatch(logError("Error loading CostTypes.", error));
        throw error;
    }
};

export class CostType {
    public readonly name: string;
    public readonly id: number;

    constructor(
        name: string,
        id: number
    ) {
        this.name = name;
        this.id = id;
    }
}


export class CostTypeMultiSelect extends CostType {
    [immerable] = true;

    private _isSelected: boolean;

    constructor(
        costType: CostType,
        _isSelected: boolean
    ) {
        super(
            costType.name,
            costType.id
        );
        this._isSelected = _isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}
