import React from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import {
    selectPartnersSort,
    setPartnersSort,
    PartnersSortField
} from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { SortDirection } from "utils/sortUtils";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(25)
    }
}))(Menu);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

const Sort: React.FC = () => {
    const dispatch = useAppDispatch();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const partnersSort = useAppSelector(selectPartnersSort);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSortClick = (field: PartnersSortField) => () => {
        let direction = SortDirection.ASC;
        if (partnersSort.field === field) {
            direction = partnersSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newPartnersSort = { ...partnersSort, field, direction };
        dispatch(setPartnersSort(newPartnersSort));
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                color="default"
                disableElevation
                startIcon={<ArrowDownwardIcon />}
                onClick={handleClick}
                data-cy="btn-sort"
            >
                Sort
            </Button>
            <StyledMenu
                open={open}
                onClose={handleClose}
                variant="menu"
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <StyledMenuItem onClick={handleSortClick(PartnersSortField.PartnerName)} data-current={partnersSort.field === PartnersSortField.PartnerName}>
                    <Typography variant="body1" component="div">
                        Partner name
                    </Typography>
                    <Spacer />
                    {partnersSort.field !== PartnersSortField.PartnerName || partnersSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(PartnersSortField.Headroom)} data-current={partnersSort.field === PartnersSortField.Headroom}>
                    <Typography variant="body1" component="div">
                        Headroom
                    </Typography>
                    <Spacer />
                    {partnersSort.field !== PartnersSortField.Headroom || partnersSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(PartnersSortField.OptimisedSales)} data-current={partnersSort.field === PartnersSortField.OptimisedSales}>
                    <Typography variant="body1" component="div">
                        Optimised sales
                    </Typography>
                    <Spacer />
                    {partnersSort.field !== PartnersSortField.OptimisedSales || partnersSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(PartnersSortField.TotalEstimatedSales)} data-current={partnersSort.field === PartnersSortField.TotalEstimatedSales}>
                    <Typography variant="body1" component="div">
                        Total estimated sales
                    </Typography>
                    <Spacer />
                    {partnersSort.field !== PartnersSortField.TotalEstimatedSales || partnersSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(PartnersSortField.ClientSourcedSales)} data-current={partnersSort.field === PartnersSortField.ClientSourcedSales}>
                    <Typography variant="body1" component="div">
                        {clientName} sourced sales
                    </Typography>
                    <Spacer />
                    {partnersSort.field !== PartnersSortField.ClientSourcedSales || partnersSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
};

export default Sort;
