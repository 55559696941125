import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { selectUserInfo } from "modules/auth/authSlice";
import {
    selectRangesProductCategory,
    selectRangesProductName,
    selectRangesHeadroom,
    selectRangesOptimisedSales,
    selectRangesTotalEstimatedSales,
    selectRangesClientSourcedSales,
    selectRangesFilter,
    selectIsRangesFilterModified,
    setRangesFilter,
    clearRangesFilter,
    RangesFilter
} from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(40),
        maxHeight: "70vh"
    }
}))(Menu);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    }
}))(TextField);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const categories = useAppSelector(selectRangesProductCategory);
    const subCategories = useAppSelector(selectRangesProductName);
    const headroom = useAppSelector(selectRangesHeadroom);
    const optimisedSales = useAppSelector(selectRangesOptimisedSales);
    const totalEstimatedSales = useAppSelector(selectRangesTotalEstimatedSales);
    const clientSourcedSales = useAppSelector(selectRangesClientSourcedSales);
    const rangesFilter = useAppSelector(selectRangesFilter);
    const isRangesFilterModified = useAppSelector(selectIsRangesFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentRangesFilter, setCurrentRangesFilter] = React.useState<RangesFilter>(rangesFilter);

    // Headroom slider values
    const headroomPercentileThresholds = headroom.percentileThresholds;
    const headroomSliderMinValue = headroomPercentileThresholds.indexOf(Math.max(headroom.minPercentileThreshold, rangesFilter.headroom[0]));
    const headroomSliderMaxValue = headroomPercentileThresholds.indexOf(Math.min(headroom.maxPercentileThreshold, rangesFilter.headroom[1]));
    const headroomSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < headroomPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(headroomPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [headroomPercentileThresholds]);

    // Optimised sales slider values
    const optimisedSalesPercentileThresholds = optimisedSales.percentileThresholds;
    const optimisedSalesSliderMinValue = optimisedSalesPercentileThresholds.indexOf(Math.max(optimisedSales.minPercentileThreshold, rangesFilter.optimisedSales[0]));
    const optimisedSalesSliderMaxValue = optimisedSalesPercentileThresholds.indexOf(Math.min(optimisedSales.maxPercentileThreshold, rangesFilter.optimisedSales[1]));
    const optimisedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < optimisedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(optimisedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [optimisedSalesPercentileThresholds]);

    // Total estimated sales slider values
    const totalEstimatedSalesPercentileThresholds = totalEstimatedSales.percentileThresholds;
    const totalEstimatedSalesSliderMinValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.max(totalEstimatedSales.minPercentileThreshold, rangesFilter.totalEstimatedSales[0]));
    const totalEstimatedSalesSliderMaxValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.min(totalEstimatedSales.maxPercentileThreshold, rangesFilter.totalEstimatedSales[1]));
    const totalEstimatedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < totalEstimatedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(totalEstimatedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [totalEstimatedSalesPercentileThresholds]);

    // Client sourced sales slider values
    const clientSourcedSalesPercentileThresholds = clientSourcedSales.percentileThresholds;
    const clientSourcedSalesSliderMinValue = clientSourcedSalesPercentileThresholds.indexOf(Math.max(clientSourcedSales.minPercentileThreshold, rangesFilter.clientSourcedSales[0]));
    const clientSourcedSalesSliderMaxValue = clientSourcedSalesPercentileThresholds.indexOf(Math.min(clientSourcedSales.maxPercentileThreshold, rangesFilter.clientSourcedSales[1]));
    const clientSourcedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < clientSourcedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(clientSourcedSalesPercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [clientSourcedSalesPercentileThresholds]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearRangesFilter());
    };

    const setRangesFilterDelayed = React.useMemo(() => {
        return _.debounce((storesFilter: RangesFilter) => dispatch(setRangesFilter(storesFilter)), 400);
    }, [dispatch]);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const category = event.target.value;
        const newRangesFilter = { ...rangesFilter, category };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    const handleSubCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subCategory = event.target.value;
        const newRangesFilter = { ...rangesFilter, subCategory };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    const handleHeadroomChange = (event: React.ChangeEvent<{}>, value: any) => {
        const headroom = [headroomPercentileThresholds[value[0]], headroomPercentileThresholds[value[1]]];
        const newRangesFilter = { ...rangesFilter, headroom };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    const handleOptimisedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const optimisedSales = [optimisedSalesPercentileThresholds[value[0]], optimisedSalesPercentileThresholds[value[1]]];
        const newRangesFilter = { ...rangesFilter, optimisedSales };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    const handleTotalEstimatedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const totalEstimatedSales = [totalEstimatedSalesPercentileThresholds[value[0]], totalEstimatedSalesPercentileThresholds[value[1]]];
        const newRangesFilter = { ...rangesFilter, totalEstimatedSales };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    const handleClientSourcedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const clientSourcedSales = [clientSourcedSalesPercentileThresholds[value[0]], clientSourcedSalesPercentileThresholds[value[1]]];
        const newRangesFilter = { ...rangesFilter, clientSourcedSales };
        setCurrentRangesFilter(newRangesFilter);
        setRangesFilterDelayed(newRangesFilter);
    };

    React.useEffect(() => {
        setCurrentRangesFilter(rangesFilter);
    }, [rangesFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isRangesFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Product category
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentRangesFilter.category}
                            onChange={handleCategoryChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select product category
                            </MenuItem>
                            {categories.map((category, index) =>
                                <MenuItem key={index} value={category}>
                                    {category}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Product sub category
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentRangesFilter.subCategory}
                            onChange={handleSubCategoryChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select product sub category
                            </MenuItem>
                            {subCategories.map((subCategory, index) =>
                                <MenuItem key={index} value={subCategory}>
                                    {subCategory}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Headroom
                        </Typography>
                        <Slider
                            value={[headroomSliderMinValue, headroomSliderMaxValue]}
                            onChange={handleHeadroomChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={headroomSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Optimised sales
                        </Typography>
                        <Slider
                            value={[optimisedSalesSliderMinValue, optimisedSalesSliderMaxValue]}
                            onChange={handleOptimisedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={optimisedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Total estimated sales
                        </Typography>
                        <Slider
                            value={[totalEstimatedSalesSliderMinValue, totalEstimatedSalesSliderMaxValue]}
                            onChange={handleTotalEstimatedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={totalEstimatedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            {clientName} sourced sales
                        </Typography>
                        <Slider
                            value={[clientSourcedSalesSliderMinValue, clientSourcedSalesSliderMaxValue]}
                            onChange={handleClientSourcedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={clientSourcedSalesSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
