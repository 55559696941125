import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { immerable } from "immer";
import { DateTime } from "luxon";

import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";

export class ProductCategory {
    public readonly id: number;
    public readonly name: string;

    constructor(
        id: number,
        name: string,
    ) {
        this.id = id;
        this.name = name;
    }
}

export class ProductCategoryMultiSelect extends ProductCategory {
    [immerable] = true;

    private _isSelected: boolean;

    constructor(
        productCategory: ProductCategory,
        _isSelected: boolean
    ) {
        super(
            productCategory.id,
            productCategory.name
        );
        this._isSelected = _isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const loadProductCategories = (referenceDate: DateTime): AppThunk<Promise<ProductCategory[]>> => async (dispatch) => {
    try {
        const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
        const endDate = referenceDate.endOf('day');
        const query = {
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [startDate, endDate]
            }],
            dimensions: [
                "D_ProductCategory.ProductCategory1"
            ],
            filters: [{
                member: "ProductSalesEstimate.SumOptimisedSales",
                operator: "set"
            }],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        let i = 1;
        const products = resultSet.rawData().map(row => new ProductCategory(
            i++,
            row["D_ProductCategory.ProductCategory1"]
        ));
        return products;
    } catch (error) {
        dispatch(logError("Error loading productCategories.", error));
        throw error;
    }
};
