import React from "react";

import { Typography, Button } from "@material-ui/core";

import SimpleDialog from "components/SimpleDialog";

import { login, selectLoggedOutVisibility } from "modules/auth/authSlice";
import { logWarning } from "modules/helpers/logger/loggerSlice";
import { useAppDispatch, useAppSelector } from "store";

const LoggedOut: React.FC = () => {
    const dispatch = useAppDispatch();
    const loggedOutVisibility = useAppSelector(selectLoggedOutVisibility);

    const handleLoginClick = () => {
        dispatch(login());
    };

    React.useEffect(() => {
        if (loggedOutVisibility.isVisible) {
            dispatch(logWarning("User have been logged out."));
        }
    }, [dispatch, loggedOutVisibility]);

    return (
        <SimpleDialog
            open={loggedOutVisibility.isVisible}
            enableClose={false}
            maxWidth="sm"
            title={
                <>
                    <Typography variant="h3" component="div" gutterBottom>
                        You have been logged out of Dash
                    </Typography>
                </>
            }
            titleAlign="center"
            content={
                <Typography variant="h4" component="div">
                    For security, your connection times out when you've been inactive for a while
                </Typography>
            }
            contentAlign="center"
            actions={
                <>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        disableElevation
                        data-cy="btn-log-in"
                        onClick={handleLoginClick}
                    >
                        Log in
                    </Button>
                </>
            }
            actionsJustifyContent="center"
        />
    );
};

export default LoggedOut;
