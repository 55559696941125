import React from "react";
import Highcharts from "highcharts";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";
import { selectProductSalesMix } from "modules/customer/tools/product/storeOpportunities/storeOpportunitiesSlice";
import { Box, useTheme } from "@material-ui/core";

const StoreSalesCategoryMix = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const products = useAppSelector(selectProductSalesMix);
    const { isLoading, hasErrors, data } = products;

    const categories = products.data.map(product => product.name);

    const optimisedSales = {
        name: "Optimised sales",
        data: data.map(product => ({
            y: product.sales.optimisedSales,
            category: product.sales.name,
        })),
        color: colourPalette.categorical[0],
        type: "bar",
    };

    const estimatedSales = {
        name: "Estimated sales",
        data: data.map(product => ({
            y: product.sales.estimatedSales,
            category: product.sales.name,
        })),
        color: colourPalette.categorical[1],
        type: "bar",
    };

    const clientSourcedSales = {
        name: `${clientName} sourced sales`,
        data: data.map(product => ({
            y: product.sales.clientSourcedSales,
            category: product.sales.name,
        })),
        color: colourPalette.categorical[2],
        type: "bar",
    };

    const series = [optimisedSales, estimatedSales, clientSourcedSales];

    const options: Highcharts.Options = {
        chart: {
            height: 600,
            type: "bar",
            scrollablePlotArea: {
                minHeight: 85 * products.data.length
            },
            spacingRight: 20
        },
        legend: {
            enabled: true
        },
        xAxis: {
            categories,
            labels: {
                enabled: true
            },
            scrollbar: {
                enabled: true
            }
        },
        yAxis: {
            labels: {
                enabled: true
            },
            gridLineWidth: 1
        },
        plotOptions: {
            bar: {
                groupPadding: 0.1,
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            formatter: function () {
                if (!this.points) {
                    return "";
                }
                const points = this.points ?? [];
                const values = points.map(point => numberFormatter.toGBP(point.y, 1));
                const captions = points.map(point => point.series.name);
                const categoryFormattingArr = points.map((point, index) => {
                    return `color:${String(point.color)};font-weight:bold`;
                });
                categoryFormattingArr.push(`color:${theme.palette.text.primary};font-weight:bold`);
                const relevantHeadroomValue = products.data.find(product => product.name === points[0].x)?.sales.salesHeadroom ?? 0;
                values.push(numberFormatter.toGBP(relevantHeadroomValue, 1));
                captions.push("Headroom");
                return `<table>${stringUtils.tooltipHTML(captions, {
                    header: `${points.at(0)?.key ?? ""}`,
                    values,
                    categoryFormattingArr
                })}</table>`;
            },
            useHTML: true,
            shared: true
        },
        //@ts-ignore
        series
    };

    return (
        <Box>
            <Bar loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default StoreSalesCategoryMix;
