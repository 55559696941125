import { Button, useTheme, withStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore, GetApp } from "@material-ui/icons";
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import Spacer from "components/Spacer";
import { DateTime } from "luxon";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectFlattenedStores } from "modules/customer/tools/product/productSlice";
import React from "react";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledDataGrid = withStyles(theme => ({
    root: {
        border: 'none',
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        '& .MuiCheckbox-root': {
            color: theme.palette.text.primary,
            '&.Mui-checked': {
                //@ts-ignore
                color: theme.palette.tertiary.main,
            },
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        }
    }
}))(DataGridPro);

const SalesByStore: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const { data: rows, isLoading } = useAppSelector(selectFlattenedStores);

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Store name',
            sortable: true,
        },
        {
            field: 'address',
            flex: 1,
            headerName: 'Address',
            sortable: true,
            hide: true
        },
        {
            field: 'region',
            flex: 1,
            headerName: 'Region',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'clientRegion',
            flex: 1,
            headerName: `${userInfo.companyDisplayName} region`,
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'sizeInSquareFeet',
            type: 'number',
            flex: 1,
            headerName: `Sqftage`,
            sortable: true,
            hide: true,
            renderCell: (params) => numberFormatter.toDecimalPlaces(params.value ?? 0, 0),
        },
        {
            field: 'group',
            flex: 1,
            headerName: `Group`,
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'format',
            flex: 1,
            headerName: 'Format',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'segment',
            flex: 1,
            headerName: 'Segment',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'numberOfEmployees',
            type: 'number',
            flex: 1,
            headerName: 'Employees',
            sortable: true,
            hide: true
        },
        {
            field: 'openingDate',
            type: 'date',
            flex: 1,
            headerName: 'Opening date',
            sortable: true,
            hide: true,
            valueGetter: (params) => {
                const dateTime = params.value as DateTime;
                return dateTime?.isValid ? dateTime.toJSDate() : null;
            },
        },
        {
            field: 'clientSourcedSales',
            type: 'number',
            flex: 1,
            headerName: `${userInfo.companyDisplayName} sourced sales`,
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'estimatedSales',
            type: 'number',
            flex: 1,
            headerName: 'Estimated sales',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'optimisedSales',
            type: 'number',
            flex: 1,
            headerName: 'Optimised sales',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'salesHeadroom',
            type: 'number',
            flex: 1,
            headerName: 'Headroom',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        }
        //{
        //    field: 'confidenceInterval',
        //    type: 'number',
        //    flex: 1,
        //    headerName: 'Confidence interval',
        //    sortable: true,
        //    renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, true, 0),
        //},
    ];

    const columndsWithWidth = columns.map(column => {
        if (column.type === 'singleSelect') {
            //@ts-expect-error
            column.valueOptions = Array.from(new Set(rows.map(row => row[column.field])));
        }
        return {
            ...column,
            minWidth: 150
        };
    });

    const apiRef = useGridApiRef();

    const handleExport = () => {
        apiRef.current.exportDataAsCsv({ fileName: "Dash stores export" });
    };

    const CustomExportButton = () => {
        return (
            <Button
                // @ts-ignore 
                style={{ color: theme.palette.tertiary.main }}
                startIcon={<GetApp />}
                onClick={handleExport}
                size="small"
            >
                Export
            </Button>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ paddingRight: theme.spacing(2) }}>
                <GridToolbarColumnsButton style={{ color: theme.palette.text.primary }} />
                <GridToolbarFilterButton style={{ color: theme.palette.text.primary }} />
                <Spacer />
                {/* @ts-ignore */}
                <CustomExportButton style={{ color: theme.palette.tertiary.main }} />
            </GridToolbarContainer>
        );
    }

    return (
        <StyledDataGrid
            rows={rows}
            columns={columndsWithWidth}
            checkboxSelection
            disableSelectionOnClick
            loading={isLoading}
            components={{
                Toolbar: CustomToolbar,
                ColumnSortedAscendingIcon: ExpandLess,
                ColumnSortedDescendingIcon: ExpandMore
            }}
            sortingOrder={['asc', 'desc']}
            hideFooter
            apiRef={apiRef}
        />
    );
};

export default SalesByStore;
