import React from "react";

import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";
import { Box, Typography } from "@material-ui/core";

const SalesMixTitle: React.FC = () => {
    const selectedStore = useAppSelector(selectSelectedStore);
    return (
        <Box paddingTop={1}>
            <Typography variant="h6">{selectedStore?.name} store product sales category mix</Typography>
        </Box>
    );
};

export default SalesMixTitle;
