import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedRange } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);

    return (
        <>
            Identify the best stores for {selectedRange?.name} by analysing historical trends and headroom potential.
            Optimise product placement to align with local demand and drive sales growth across {selectedPartner?.name}'s estate.
        </>
    );
};

export default Subtitle;
