import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Range } from "modules/customer/tools/product/storeRangeFilters/range";
import { Partner } from "modules/customer/tools/product/partner";

export const loadNumberStoresSellingRange = (
    referenceDate: DateTime,
    partner?: Partner,
    selectedRange?: Range,
    selectedStoreId?: string
): AppThunk<Promise<number>> => async (dispatch) => {
    try {
        if (!referenceDate || !partner || !selectedRange) {
            return [];
        }

        const startDate = referenceDate.minus({ months: 12 }).startOf('month').startOf('day');
        const endDate = referenceDate.endOf('day');
        const query = {
            measures: [
                "ProductSalesEstimate.CountDistinctStoreIds"
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [startDate, endDate]
            }],
            filters: [{
                member: "ProductSalesEstimate.FK_ProductCategory",
                operator: "equals",
                values: [String(selectedRange.id)]
            }, {
                member: "D_Store.Group",
                operator: "equals",
                values: [partner.name]
            }],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        if (selectedStoreId) {
            query.filters.push({
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [String(selectedStoreId)]
            });
        }

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        return resultSet.rawData()[0]["ProductSalesEstimate.CountDistinctStoreIds"];
    } catch (error) {
        dispatch(logError("Error loading NumberOfStoresSellingRange.", error));
        throw error;
    }
};
