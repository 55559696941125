import React from "react";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";
import { Box, Typography } from "@material-ui/core";

const SalesByProductTitle: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);
    return (
        <Box paddingTop={1}>
            <Typography variant="h6">Optimised, estimated and {userInfo?.companyDisplayName} sales by product sub category</Typography>
        </Box>
    );
};

export default SalesByProductTitle;
