import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedRange } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);

    return (
        <>
            Analyse {selectedRange?.name}'s sales trends across the {selectedPartner?.name}'s estate. Track historical
            performance to identify months where aligning inventory with demand could capture additional revenue.
        </>
    );
};

export default Subtitle;
