import React from "react";
import Highcharts, { dateFormat } from "highcharts";

import { Box } from "@material-ui/core";

import StockLine from "components/visuals/StockLine";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";
import { selectHasErrors, selectIsLoading, selectRangeMonthlySales } from "modules/customer/tools/product/rangeOpportunities/rangeOpportunitiesSlice";
import { selectUserInfo } from "modules/auth/authSlice";

const RangeHistoricSales: React.FC = () => {
    const colourPalette = useColourPalette();
    const userInfo = useAppSelector(selectUserInfo);
    const clientName = userInfo.companyDisplayName;
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const rangeMonthlySales = useAppSelector(selectRangeMonthlySales);

    const optimisedSales: Highcharts.Series = {
        name: "Optimised sales",
        //@ts-ignore
        data: rangeMonthlySales.map(salesItem =>
            [salesItem.date.toMillis(), salesItem.sales.optimisedSales]
        ),
        color: colourPalette.categorical[0]
    };

    const estimatedSales: Highcharts.Series = {
        name: "Total estimated sales",
        //@ts-ignore
        data: rangeMonthlySales.map(salesItem =>
            [salesItem.date.toMillis(), salesItem.sales.estimatedSales]
        ),
        color: colourPalette.categorical[1]
    };

    const clientSourcedSalesSeries: Highcharts.Series = {
        name: `${clientName} sourced sales`,
        //@ts-ignore
        data: rangeMonthlySales.map(salesItem =>
            [salesItem.date.toMillis(), salesItem.sales.clientSourcedSales]
        ),
        color: colourPalette.categorical[2]
    };

    const series = [optimisedSales, estimatedSales, clientSourcedSalesSeries];
    const navigationSeries = series.map(item => ({
        ...item,
        type: "spline"
    }));

    const options: Highcharts.Options = {
        chart: {
            height: 600
        },
        yAxis: {
            title: {
                text: "Sales (£)"
            },
        },
        legend: {
            enabled: true
        },
        //@ts-ignore
        series,
        navigator: {
            series: navigationSeries
        },
        rangeSelector: {
            allButtonsEnabled: true,
            selected: 0,
            buttons: [{
                type: "month",
                count: 12,
                text: "Month",
                dataGrouping: {
                    forced: true,
                    units: [["month", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 24,
                text: "Quarter",
                dataGrouping: {
                    forced: true,
                    units: [["month", [3]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 3,
                text: "Year",
                dataGrouping: {
                    forced: true,
                    units: [["year", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }]
        },
        tooltip: {
            enabled: true,
            shared: true,
            headerFormat: ``,
            footerFormat: ``,
            //@ts-ignore
            formatter: function () {
                //@ts-ignore
                const header = dateFormat("%B %Y", this.points[0].x);
                const categoriesArr: string[] = [];
                const categoriesFormatArr: string[] = [];
                const valuesArr: string[] = [];

                // @ts-ignore
                this.points.forEach((point, index) => {
                    categoriesArr.push(point.series.name);
                    categoriesFormatArr.push(`color:${point.series.color};font-weight:bold`);

                    const salesValue = numberFormatter.toGBP(point.y);
                    valuesArr.push(salesValue);
                });

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr
                    }
                )}</table>`;
            },
            useHTML: true
        }
    };

    return (
        <Box data-cy="range-historic-sales" >
            <StockLine loading={isLoading} error={hasErrors} options={options} globalOptions={{}} />
        </Box >
    );
};

export default RangeHistoricSales;
