import React from "react";

import { Box } from "@material-ui/core";
import { MapRef } from "react-map-gl";

import MapBase from "components/visuals/maps/MapBase";
import SelectedStoreMarker from "components/visuals/maps/SelectedStoreMarker";

import CoreRetailHub from "./CoreRetailHub";
import Legend from "./Legend";
import Marker from "./Marker";

import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { Competitor } from "modules/customer/tools/product/productStoreFit/competitor";
import {
    selectCompetitionAlignmentHasErrors,
    selectCompetitionAlignmentIsLoading,
    selectCompetitorsWithin5Kms,
    selectCoreRetailHub
} from "modules/customer/tools/product/productStoreFit/productStoreFitSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const mapRef = React.useRef<MapRef>(null);
    const store = useAppSelector(selectSelectedStore);
    const isLoading = useAppSelector(selectCompetitionAlignmentIsLoading);
    const hasErrors = useAppSelector(selectCompetitionAlignmentHasErrors);
    const competitors = useAppSelector(selectCompetitorsWithin5Kms);
    const coreRetailHub = useAppSelector(selectCoreRetailHub);
    const [hoveredCompetitor, setHoveredCompetitor] = React.useState<Competitor | undefined>(undefined);

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: store?.latitude ?? 0,
        longitude: store?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: store?.latitude ?? 0,
                longitude: store?.longitude ?? 0
            };
        });
    }, [store]);

    return (
        <Box data-cy="competition-map">
            <MapBase
                loading={isLoading}
                error={hasErrors}
                title={`Competitors in the surrounding area of your ${store?.name} store`}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                initialViewport={initialViewport}
                addGeocoder={false}
                addNavigationControl={true}
                addRecenterButton={true}
                dataCy="competitors-map"
                mapRef={mapRef}
                downloadData={competitors}
                addFullscreenButton={true}
            >
                <SelectedStoreMarker latitude={store?.latitude ?? 0} longitude={store?.longitude ?? 0} />
                {competitors?.map((competitor, index) =>
                    <Marker
                        key={index}
                        competitor={competitor}
                        onTooltipOpen={() => setHoveredCompetitor(competitor)}
                        onTooltipClose={() => setHoveredCompetitor(undefined)}
                    />
                )}
                <CoreRetailHub />
                <Legend store={store} competitor={hoveredCompetitor} coreRetailHub={coreRetailHub} />
            </MapBase>
        </Box>
    );
};

export default Map;
