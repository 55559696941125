import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { Store } from "modules/customer/tools/product/store";
import {
    ProductChapter,
    selectCurrentChapter,
    selectFilterStores,
    selectReferenceDate,
    selectSelectedPartner,
    selectSelectedRange,
    selectSelectedStore,
    selectStores
} from "modules/customer/tools/product/productSlice";
import { loadRanges, Range } from "./range";
import { logError } from "modules/helpers/logger/loggerSlice";
import { DataWrapper } from "domain/dataWrapper";
import { selectPartnerFiltersVisibility } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";
import mathUtils from "utils/mathUtils";

interface FiltersVisibility {
    isVisible: boolean
}

interface LoadRangeSelectionResponse {
    ranges: Range[]
}

interface SliderThresholds {
    minPercentileThreshold: number,
    maxPercentileThreshold: number,
    percentileThresholds: number[]
}

export enum StoreRangeFilterStep {
    SelectStore,
    SelectRange
}

export interface StoresSearch {
    name: string
}

export interface StoresFilter {
    headroom: number[],
    optimisedSales: number[],
    totalEstimatedSales: number[],
    clientSourcedSales: number[]
}

export enum StoresSortField {
    StoreName,
    Headroom,
    OptimisedSales,
    TotalEstimatedSales,
    ClientSourcedSales
}

interface StoresSort {
    field: StoresSortField,
    direction: SortDirection
}

export interface RangesSearch {
    name: string
}

export interface RangesFilter {
    category: string,
    subCategory: string,
    headroom: number[],
    optimisedSales: number[],
    totalEstimatedSales: number[],
    clientSourcedSales: number[]
}

export enum RangesSortField {
    RangeName,
    Headroom,
    OptimisedSales,
    TotalEstimatedSales,
    ClientSourcedSales
}

interface RangesSort {
    field: RangesSortField,
    direction: SortDirection
}

interface FiltersState {
    storeRangeFiltersVisibility: FiltersVisibility,
    activeStep: StoreRangeFilterStep,
    storesSearch: StoresSearch,
    storesFilter: StoresFilter,
    storesSort: StoresSort,
    rangesSearch: RangesSearch,
    rangesFilter: RangesFilter,
    rangesSort: RangesSort,
    ranges: DataWrapper<Range[]>,
    candidateStore?: Store,
    candidateRange?: Range
}

const initialState: FiltersState = {
    storeRangeFiltersVisibility: {
        isVisible: false
    },
    activeStep: StoreRangeFilterStep.SelectStore,
    storesSearch: {
        name: ""
    },
    storesFilter: {
        headroom: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        optimisedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        totalEstimatedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        clientSourcedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    storesSort: {
        field: StoresSortField.StoreName,
        direction: SortDirection.ASC
    },
    rangesSearch: {
        name: ""
    },
    rangesFilter: {
        category: "",
        subCategory: "",
        headroom: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        optimisedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        totalEstimatedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        clientSourcedSales: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    rangesSort: {
        field: RangesSortField.RangeName,
        direction: SortDirection.ASC
    },
    ranges: { isLoading: false, hasErrors: false, data: [] },
    candidateStore: undefined,
    candidateRange: undefined
};

const storeRangeFiltersSlice = createSlice({
    name: "customer/tools/product/storeRangeFilters",
    initialState,
    reducers: {
        showStoreRangeFilters: (state) => {
            state.storeRangeFiltersVisibility.isVisible = true;
        },
        hideStoreRangeFilters: (state) => {
            state.storeRangeFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<StoreRangeFilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = initialState.storesSearch;
        },
        setStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.storesFilter = action.payload;
        },
        clearStoresFilter: (state) => {
            state.storesFilter = initialState.storesFilter;
        },
        setStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.storesSort = action.payload;
        },
        clearStoresSort: (state) => {
            state.storesSort = initialState.storesSort;
        },
        setCandidateStore: (state, action: PayloadAction<Store>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        },
        setRangesSearch: (state, action: PayloadAction<RangesSearch>) => {
            state.rangesSearch = action.payload;
        },
        clearRangesSearch: (state) => {
            state.rangesSearch = initialState.rangesSearch;
        },
        setRangesFilter: (state, action: PayloadAction<RangesFilter>) => {
            state.rangesFilter = action.payload;
        },
        clearRangesFilter: (state) => {
            state.rangesFilter = initialState.rangesFilter;
        },
        setRangesSort: (state, action: PayloadAction<RangesSort>) => {
            state.rangesSort = action.payload;
        },
        clearRangesSort: (state) => {
            state.rangesSort = initialState.rangesSort;
        },
        clearRanges: (state) => {
            state.ranges = initialState.ranges;
        },
        setCandidateRange: (state, action: PayloadAction<Range>) => {
            state.candidateRange = action.payload;
        },
        clearCandidateRange: (state) => {
            state.candidateRange = initialState.candidateRange;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadRangeSelection.pending, (state: FiltersState) => {
            state.ranges.isLoading = true;
            state.ranges.hasErrors = false;
            state.ranges.data = initialState.ranges.data;
        });
        builder.addCase(loadRangeSelection.rejected, (state: FiltersState) => {
            state.ranges.isLoading = false;
            state.ranges.hasErrors = true;
            state.ranges.data = initialState.ranges.data;
        });
        builder.addCase(loadRangeSelection.fulfilled, (state: FiltersState, action: PayloadAction<LoadRangeSelectionResponse>) => {
            state.ranges.isLoading = false;
            state.ranges.hasErrors = false;
            state.ranges.data = action.payload.ranges;
        });
    }
});

export const loadRangeSelection = createAppAsyncThunk(
    "customer/tools/product/storeRangeFilters/loadRangeSelection",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const referenceDate = selectReferenceDate(state);
            const partner = selectSelectedPartner(state);
            const selectedStore = selectCandidateStore(state);

            const ranges = await thunkAPI.dispatch(loadRanges(referenceDate, partner, selectedStore));
            const loadStoreOpportunitiesResponse: LoadRangeSelectionResponse = {
                ranges
            };
            return loadStoreOpportunitiesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Range Selection.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const {
    showStoreRangeFilters,
    hideStoreRangeFilters,
    setActiveStep,
    setStoresSearch,
    setStoresFilter,
    setStoresSort,
    setCandidateStore,
    clearActiveStep,
    clearCandidateStore,
    clearStoresSearch,
    clearStoresFilter,
    clearStoresSort,
    setRangesSearch,
    setRangesFilter,
    setRangesSort,
    clearRangesSearch,
    clearRangesFilter,
    clearRangesSort,
    clearCandidateRange,
    clearRanges,
    setCandidateRange
} = storeRangeFiltersSlice.actions;

export const resetStoreRangeFilters = (): AppThunk => async (dispatch) => {
    dispatch(storeRangeFiltersSlice.actions.hideStoreRangeFilters());
    dispatch(storeRangeFiltersSlice.actions.clearActiveStep());
    dispatch(storeRangeFiltersSlice.actions.clearStoresSearch());
    dispatch(storeRangeFiltersSlice.actions.clearStoresFilter());
    dispatch(storeRangeFiltersSlice.actions.clearStoresSort());
    dispatch(storeRangeFiltersSlice.actions.clearCandidateStore());
    dispatch(storeRangeFiltersSlice.actions.clearRanges());
    dispatch(storeRangeFiltersSlice.actions.clearCandidateRange());
    dispatch(storeRangeFiltersSlice.actions.clearRangesSearch());
    dispatch(storeRangeFiltersSlice.actions.clearRangesFilter());
    dispatch(storeRangeFiltersSlice.actions.clearRangesSort());
};

export const clearRangeSelection = (): AppThunk => async (dispatch) => {
    dispatch(storeRangeFiltersSlice.actions.clearRanges());
};

export const selectStoreRangeFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.storeRangeFilters.storeRangeFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.activeStep;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.storesSearch;
};

export const selectStoresFilter = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.storesFilter;
};

export const selectStoresSort = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.storesSort;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.candidateStore;
};

export const selectRangesSearch = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.rangesSearch;
};

export const selectRangesFilter = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.rangesFilter;
};

export const selectRangesSort = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.rangesSort;
};

export const selectRanges = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.ranges;
};

export const selectCandidateRange = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.candidateRange;
};

const generateSliderThresholds = (values: number[]): SliderThresholds => {
    const percentileThresholds = mathUtils.percentileThresholds(values, 5);
    percentileThresholds.push(values[(values.length - 1)]);
    return {
        minPercentileThreshold: percentileThresholds[0],
        maxPercentileThreshold: percentileThresholds[percentileThresholds.length - 1],
        percentileThresholds
    };
};

export const selectStoresHeadroom = createSelector(
    (state: RootState) => selectStores(state),
    (stores) => {
        const headroom = stores.data
            .map(store => store.sales.salesHeadroom)
            .sort((a, b) => a - b);
        return generateSliderThresholds(headroom);
    }
);

export const selectStoresOptimisedSales = createSelector(
    (state: RootState) => selectStores(state),
    (stores) => {
        const optimisedSales = stores.data
            .map(store => store.sales.optimisedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(optimisedSales);
    }
);

export const selectStoresTotalEstimatedSales = createSelector(
    (state: RootState) => selectStores(state),
    (stores) => {
        const estimatedSales = stores.data
            .map(store => store.sales.estimatedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(estimatedSales);
    }
);

export const selectStoresClientSourcedSales = createSelector(
    (state: RootState) => selectStores(state),
    (stores) => {
        const clientSourcedSales = stores.data
            .map(store => store.sales.clientSourcedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(clientSourcedSales);
    }
);

export const selectIsStoresFilterModified = createSelector(
    selectStoresFilter,
    (storesFilter) => {
        return storesFilter.headroom[0] !== initialState.storesFilter.headroom[0]
            || storesFilter.headroom[1] !== initialState.storesFilter.headroom[1]
            || storesFilter.optimisedSales[0] !== initialState.storesFilter.optimisedSales[0]
            || storesFilter.optimisedSales[1] !== initialState.storesFilter.optimisedSales[1]
            || storesFilter.totalEstimatedSales[0] !== initialState.storesFilter.totalEstimatedSales[0]
            || storesFilter.totalEstimatedSales[1] !== initialState.storesFilter.totalEstimatedSales[1]
            || storesFilter.clientSourcedSales[0] !== initialState.storesFilter.clientSourcedSales[0]
            || storesFilter.clientSourcedSales[1] !== initialState.storesFilter.clientSourcedSales[1];
    }
);

export const selectFilteredStores = createSelector(
    (state: RootState) => selectFilterStores(state),
    selectStoresSearch,
    selectStoresFilter,
    selectStoresSort,
    (stores, search, filter, sort) => {
        const { isLoading, hasErrors } = stores;
        const filteredStores: DataWrapper<Store[]> = {
            isLoading,
            hasErrors,
            data: []
        };

        const searchString = search.name.toLowerCase();
        filteredStores.data = stores.data.filter(store =>
            (!searchString || store.name.toLowerCase().includes(searchString))
            && store.sales.salesHeadroom >= filter.headroom[0]
            && store.sales.salesHeadroom <= filter.headroom[1]
            && store.sales.optimisedSales >= filter.optimisedSales[0]
            && store.sales.optimisedSales <= filter.optimisedSales[1]
            && store.sales.estimatedSales >= filter.totalEstimatedSales[0]
            && store.sales.estimatedSales <= filter.totalEstimatedSales[1]
            && store.sales.clientSourcedSales >= filter.clientSourcedSales[0]
            && store.sales.clientSourcedSales <= filter.clientSourcedSales[1]
        );

        filteredStores.data.sort((a, b) => {
            switch (sort.field) {
                case StoresSortField.Headroom:
                    return numberSortExpression(a.sales.salesHeadroom, b.sales.salesHeadroom, sort.direction);
                case StoresSortField.OptimisedSales:
                    return numberSortExpression(a.sales.optimisedSales, b.sales.optimisedSales, sort.direction);
                case StoresSortField.TotalEstimatedSales:
                    return numberSortExpression(a.sales.estimatedSales, b.sales.estimatedSales, sort.direction);
                case StoresSortField.ClientSourcedSales:
                    return numberSortExpression(a.sales.clientSourcedSales, b.sales.clientSourcedSales, sort.direction);
                case StoresSortField.StoreName:
                default:
                    return stringSortExpression(a.name, b.name, sort.direction);
            }
        });

        return filteredStores;
    }
);

export const selectRangesProductCategory = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const productCategories = ranges.data
            .map(range => range.productCategory)
            .sort((a, b) => stringSortExpression(a, b, SortDirection.ASC));
        return Array.from(new Set(productCategories));
    }
);

export const selectRangesProductName = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const productNames = ranges.data
            .map(range => range.name)
            .sort((a, b) => stringSortExpression(a, b, SortDirection.ASC));
        return Array.from(new Set(productNames));
    }
);

export const selectRangesHeadroom = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const headroom = ranges.data
            .map(range => range.sales.salesHeadroom)
            .sort((a, b) => a - b);
        return generateSliderThresholds(headroom);
    }
);

export const selectRangesOptimisedSales = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const optimisedSales = ranges.data
            .map(range => range.sales.optimisedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(optimisedSales);
    }
);

export const selectRangesTotalEstimatedSales = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const estimatedSales = ranges.data
            .map(range => range.sales.estimatedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(estimatedSales);
    }
);

export const selectRangesClientSourcedSales = createSelector(
    (state: RootState) => selectRanges(state),
    (ranges) => {
        const clientSourcedSales = ranges.data
            .map(range => range.sales.clientSourcedSales)
            .sort((a, b) => a - b);
        return generateSliderThresholds(clientSourcedSales);
    }
);

export const selectIsRangesFilterModified = createSelector(
    selectRangesFilter,
    (rangesFilter) => {
        return rangesFilter.category !== initialState.rangesFilter.category
            || rangesFilter.subCategory !== initialState.rangesFilter.subCategory
            || rangesFilter.headroom[0] !== initialState.rangesFilter.headroom[0]
            || rangesFilter.headroom[1] !== initialState.rangesFilter.headroom[1]
            || rangesFilter.optimisedSales[0] !== initialState.rangesFilter.optimisedSales[0]
            || rangesFilter.optimisedSales[1] !== initialState.rangesFilter.optimisedSales[1]
            || rangesFilter.totalEstimatedSales[0] !== initialState.rangesFilter.totalEstimatedSales[0]
            || rangesFilter.totalEstimatedSales[1] !== initialState.rangesFilter.totalEstimatedSales[1]
            || rangesFilter.clientSourcedSales[0] !== initialState.rangesFilter.clientSourcedSales[0]
            || rangesFilter.clientSourcedSales[1] !== initialState.rangesFilter.clientSourcedSales[1];
    }
);

export const selectFilteredRanges = createSelector(
    (state: RootState) => selectRanges(state),
    selectRangesSearch,
    selectRangesFilter,
    selectRangesSort,
    (ranges, search, filter, sort) => {
        const { isLoading, hasErrors } = ranges;
        const filteredRanges: DataWrapper<Range[]> = {
            isLoading,
            hasErrors,
            data: []
        };

        const searchString = search.name.toLowerCase();
        filteredRanges.data = ranges.data.filter(range =>
            (!searchString || range.name.toLowerCase().includes(searchString))
            && (!filter.category || range.productCategory === filter.category)
            && (!filter.subCategory || range.name === filter.subCategory)
            && range.sales.salesHeadroom >= filter.headroom[0]
            && range.sales.salesHeadroom <= filter.headroom[1]
            && range.sales.optimisedSales >= filter.optimisedSales[0]
            && range.sales.optimisedSales <= filter.optimisedSales[1]
            && range.sales.estimatedSales >= filter.totalEstimatedSales[0]
            && range.sales.estimatedSales <= filter.totalEstimatedSales[1]
            && range.sales.clientSourcedSales >= filter.clientSourcedSales[0]
            && range.sales.clientSourcedSales <= filter.clientSourcedSales[1]
        );

        filteredRanges.data.sort((a, b) => {
            switch (sort.field) {
                case RangesSortField.Headroom:
                    return numberSortExpression(a.sales.salesHeadroom, b.sales.salesHeadroom, sort.direction);
                case RangesSortField.OptimisedSales:
                    return numberSortExpression(a.sales.optimisedSales, b.sales.optimisedSales, sort.direction);
                case RangesSortField.TotalEstimatedSales:
                    return numberSortExpression(a.sales.estimatedSales, b.sales.estimatedSales, sort.direction);
                case RangesSortField.ClientSourcedSales:
                    return numberSortExpression(a.sales.clientSourcedSales, b.sales.clientSourcedSales, sort.direction);
                case RangesSortField.RangeName:
                default:
                    return stringSortExpression(a.name, b.name, sort.direction);
            }
        });

        return filteredRanges;
    }
);

export const selectTooltipHintText = createSelector(
    (state: RootState) => selectCurrentChapter(state),
    (currentChapter) => {
        let tooltipText = "";
        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                tooltipText = "Select a store to view store opportunities";
                break;
            case ProductChapter.RangeOpportunities:
                tooltipText = "Select a product range to view range opportunities";
                break;
            case ProductChapter.ProductStoreFit:
                tooltipText = "Select a store and product to view product store fit";
                break;

        }
        return tooltipText;
    }
);

export const selectOpenModalActiveStep = createSelector(
    (state: RootState) => selectCurrentChapter(state),
    (state: RootState) => selectSelectedRange(state),
    (state: RootState) => selectSelectedStore(state),
    (currentChapter, selectedRange, selectedStore) => {
        let activeStep = StoreRangeFilterStep.SelectStore;
        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                activeStep = StoreRangeFilterStep.SelectStore;
                break;
            case ProductChapter.RangeOpportunities:
                activeStep = StoreRangeFilterStep.SelectRange;
                break;
            case ProductChapter.ProductStoreFit:
                activeStep = selectedStore ? StoreRangeFilterStep.SelectRange : StoreRangeFilterStep.SelectStore;
                break;
            default:
                activeStep = selectedRange ? StoreRangeFilterStep.SelectRange : StoreRangeFilterStep.SelectStore;
                break;

        }
        return activeStep;
    }
);

export const selectIsTooltipOpen = createSelector(
    (state: RootState) => selectPartnerFiltersVisibility(state),
    (state: RootState) => selectStoreRangeFiltersVisibility(state),
    (state: RootState) => selectCurrentChapter(state),
    (state: RootState) => selectSelectedRange(state),
    (state: RootState) => selectSelectedStore(state),
    (partnerFiltersVisibility, storeRangeFiltersVisibility, currentChapter, selectedRange, selectedStore) => {
        let isTooltipOpen = false;
        if (partnerFiltersVisibility.isVisible || storeRangeFiltersVisibility.isVisible) {
            return isTooltipOpen;
        }

        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                isTooltipOpen = !selectedStore;
                break;
            case ProductChapter.RangeOpportunities:
                isTooltipOpen = !selectedRange;
                break;
            case ProductChapter.ProductStoreFit:
                isTooltipOpen = !selectedStore || !selectedRange;
                break;
            default:
                isTooltipOpen = false;
                break;

        }
        return isTooltipOpen;
    }
);


export default storeRangeFiltersSlice;
