import React from "react";

import { Grid } from "@material-ui/core";

import HeadroomPastTwelveMonths from "./HeadroomPastTwelveMonths";
import SalesByProduct from "./SalesByProduct";
import ProductCategoryFilter from "./ProductCategoryFilter";
import SalesByProductTitle from "./SalesByProductTitle";

const RangeOverview: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <HeadroomPastTwelveMonths />
            </Grid>
            <Grid item xs={12}>
                <SalesByProductTitle />
            </Grid>
            <Grid item xs={12}>
                <ProductCategoryFilter />
            </Grid>
            <Grid item xs={12}>
                <SalesByProduct />
            </Grid>
        </Grid>
    );

};

export default RangeOverview;
