import React from "react";
import { Grid } from "@material-ui/core";
import SelectedRangeSales from "./SelectedRangeSales";
import RangeHistoricSales from "./RangeHistoricSales";
import RangeSalesTitle from "./RangeSalesTitle";

const RangeInsight: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SelectedRangeSales />
            </Grid>
            <Grid item xs={12}>
                <RangeSalesTitle />
            </Grid>
            <Grid item md={12} >
                <RangeHistoricSales />
            </Grid>
        </Grid>
    );
};

export default RangeInsight;
