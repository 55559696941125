import React from "react";
import { Box } from "@material-ui/core";

import { useAppSelector } from "store";

import KPIFact from "components/visuals/KPIFact";
import { selectNumberOfStoresSellingRangeFraction } from "modules/customer/tools/product/rangeOpportunities/rangeOpportunitiesSlice";
import { selectSelectedRange } from "modules/customer/tools/product/productSlice";

const NumberOfStoresSellingSelectedRange = () => {
    const selectedRange = useAppSelector(selectSelectedRange);
    const numberOfStoresSellingSelectedRangeFraction = useAppSelector(selectNumberOfStoresSellingRangeFraction);
    const { isLoading, hasErrors } = numberOfStoresSellingSelectedRangeFraction;
    const numberOfStoresSellingRange = numberOfStoresSellingSelectedRangeFraction.data.numberOfStoresSellingRange;
    const totalStoreCount = numberOfStoresSellingSelectedRangeFraction.data.totalStoreCount;

    const formattedValueString = `${numberOfStoresSellingRange} / ${totalStoreCount}`;
    const label = `Number of stores selling ${selectedRange?.name}`;

    return (
        <Box width="100%" height="100%" data-cy="cost-opportunity">
            <KPIFact
                loading={isLoading}
                error={hasErrors}
                label={label}
                value={formattedValueString} />
        </Box>
    );
};

export default NumberOfStoresSellingSelectedRange;
