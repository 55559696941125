import { ResultSet } from "@cubejs-client/core";

import { createAppAsyncThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CostsByStore {
    public readonly id: string;
    public readonly storeId: number;
    public readonly similarStores: SimilarStore[];
    public readonly costName: string;
    public readonly costValue: number;

    constructor(
        storeId: number,
        similarStores: SimilarStore[],
        costName: string,
        costValue: number
    ) {
        this.id = `${storeId}-${costName}`;
        this.storeId = storeId;
        this.similarStores = similarStores;
        this.costName = costName;
        this.costValue = costValue;
    }
}

class SimilarStore {
    public readonly id: number;
    public readonly similarityScore: number;

    constructor(
        rawSimilarStore: RawSimilarStore,
    ) {
        this.id = Number(rawSimilarStore.destStoreId);
        this.similarityScore = rawSimilarStore.similarityScore;
    }
}

interface RawSimilarStore {
    destStoreId: number,
    similarityScore: number
}

export const loadCostsByStores = createAppAsyncThunk<
    CostsByStore[],
    { referenceDate: DateTime }
>(
    "customer/insights/cost/loadCostsByStores",
    async ({ referenceDate }, thunkAPI) => {
        try {
            const query = {
                measures: [
                    "F_Cost.SumCostValue"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day'), referenceDate.endOf('day')]
                }],
                dimensions: [
                    "D_Store.PK_Store",
                    "D_Account.AccountName",
                    "SimilarStore.SimilarStores",
                ],
                filters: [{
                    member: "SimilarStore.SimilarStores",
                    operator: "set"
                }],
                segments: ["D_Store.OpenPhysicalStores"],
                limit: 50000
            };
            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;

            return resultSet.rawData().map(row => {
                return new CostsByStore(
                    Number(row["D_Store.PK_Store"]),
                    (JSON.parse(row["SimilarStore.SimilarStores"] ?? "[]")
                        .map((rawSimilarStore: RawSimilarStore) =>
                            new SimilarStore(rawSimilarStore))),
                    row["D_Account.AccountName"],
                    Number(row["F_Cost.SumCostValue"])
                );
            });
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading costsByStore.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
