import React from "react";
import _ from "lodash";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { RangesSearch, selectRangesSearch, setRangesSearch } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const rangesSearch = useAppSelector(selectRangesSearch);
    const [currentRangesSearch, setCurrentRangesSearch] = React.useState<RangesSearch>(rangesSearch);

    const setRangesSearchDelayed = React.useMemo(() => {
        return _.debounce((rangesSearch: RangesSearch) => dispatch(setRangesSearch(rangesSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newRangesSearch = { ...rangesSearch, name };
        setCurrentRangesSearch(newRangesSearch);
        setRangesSearchDelayed(newRangesSearch);
    };

    React.useEffect(() => {
        setCurrentRangesSearch(rangesSearch);
    }, [rangesSearch]);

    return (
        <Box paddingTop={2}>
            <StyledTextField
                placeholder="Search"
                variant="outlined"
                size="small"
                color="secondary"
                value={currentRangesSearch.name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Search;
