import React from "react";

import { Box, Grid } from "@material-ui/core";

import SalesAndHeadroomByProduct from "./SalesAndHeadroomByProduct";

const RangeOpportunities: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={12} >
                <Box display="flex" flexDirection="column" >
                    <Box flexGrow={1} width='100%' height={'60vh'} >
                        <SalesAndHeadroomByProduct />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );

};

export default RangeOpportunities;
