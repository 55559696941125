import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { openStreetView as mapOpenStreetView } from "modules/helpers/maps/mapsSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import { loadPartners, Partner } from "./partner";
import { clearPartnerFilters } from "./partnerFilters/partnerFiltersSlice";
import { loadReferenceDate } from "./referenceDate";
import { loadFilterStores, loadStores, Store } from "./store";
import { loadProducts, Product } from "./product";
import { DateTime } from "luxon";
import { DataWrapper } from "domain/dataWrapper";
import { resetStoreRangeFilters } from "./storeRangeFilters/storeRangeFiltersSlice";
import { clearStoreOpportunities, loadStoreOpportunities } from "./storeOpportunities/storeOpportunitiesSlice";
import { Range } from "./storeRangeFilters/range";
import { clearProductStoreFit, loadProductStoreFit } from "./productStoreFit/productStoreFitSlice";
import { loadRangeOpportunities } from "./rangeOpportunities/rangeOpportunitiesSlice";
import { loadProductCategories, ProductCategory, ProductCategoryMultiSelect } from "./productCategory";

export enum ProductChapter {
    StoreOverview = 1,
    StoreOpportunities,
    RangeOverview,
    RangeOpportunities,
    ProductStoreFit
}

export interface StoreAndRange {
    store: Store | undefined,
    range: Range | undefined
}

interface LoadProductResponse {
    partners: Partner[],
    referenceDate: DateTime,
    productCategories: ProductCategory[],
    rangeOverviewProductCategories: ProductCategoryMultiSelect[]
}

interface ProductState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: ProductChapter,
    partners: Partner[],
    referenceDate: DateTime,
    productCategories: ProductCategory[],
    stores: DataWrapper<Store[]>,
    products: DataWrapper<Product[]>,
    filterStores: DataWrapper<Store[]>,
    selectedPartner?: Partner,
    selectedStore?: Store,
    selectedRange?: Range,
    rangeOverviewProductCategories: ProductCategoryMultiSelect[]
}

const initialState: ProductState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: ProductChapter.StoreOverview,
    partners: [],
    referenceDate: DateTime.fromMillis(0),
    productCategories: [],
    stores: { isLoading: false, hasErrors: false, data: [] },
    products: { isLoading: false, hasErrors: false, data: [] },
    filterStores: { isLoading: false, hasErrors: false, data: [] },
    selectedPartner: undefined,
    selectedStore: undefined,
    selectedRange: undefined,
    rangeOverviewProductCategories: []
};

const productSlice = createSlice({
    name: "customer/tools/product",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<ProductChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        choosePartner: (state, action: PayloadAction<Partner>) => {
            state.selectedPartner = action.payload;
        },
        chooseStore: (state, action: PayloadAction<Store>) => {
            state.selectedStore = action.payload;
        },
        chooseRange: (state, action: PayloadAction<Range>) => {
            state.selectedRange = action.payload;
        },
        chooseStoreAndRange: (state, action: PayloadAction<StoreAndRange>) => {
            state.selectedStore = action.payload.store;
            state.selectedRange = action.payload.range;
        },
        clearPartners: (state) => {
            state.partners = initialState.partners;
        },
        clearSelectedPartner: (state) => {
            state.selectedPartner = initialState.selectedPartner;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = initialState.referenceDate;
        },
        clearProductCategories: (state) => {
            state.productCategories = initialState.productCategories;
        },
        toggleRangeOverviewProductCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const categoryName = action.payload;
            state.rangeOverviewProductCategories.find(category => category.name === categoryName)?.toggleIsSelected();
        },
        chooseAllRangeOverviewProductCategories: (state) => {
            state.rangeOverviewProductCategories.forEach(category => category.setIsSelected(true));
        },
        deselectAllRangeOverviewProductCategories: (state) => {
            state.rangeOverviewProductCategories.forEach(category => category.setIsSelected(false));
        },
        clearRangeOverviewProductCategories: (state) => {
            state.rangeOverviewProductCategories = initialState.rangeOverviewProductCategories;
        },
        clearStores: (state) => {
            state.stores = initialState.stores;
        },
        clearFilterStores: (state) => {
            state.filterStores = initialState.filterStores;
        },
        clearSelectedStore: (state) => {
            state.selectedStore = initialState.selectedStore;
        },
        clearSelectedRange: (state) => {
            state.selectedRange = initialState.selectedRange;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadProduct.pending, (state: ProductState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.partners = initialState.partners;
            state.referenceDate = initialState.referenceDate;
            state.productCategories = initialState.productCategories;
            state.rangeOverviewProductCategories = initialState.rangeOverviewProductCategories;
        });
        builder.addCase(loadProduct.rejected, (state: ProductState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.partners = initialState.partners;
            state.referenceDate = initialState.referenceDate;
            state.productCategories = initialState.productCategories;
            state.rangeOverviewProductCategories = initialState.rangeOverviewProductCategories;
        });
        builder.addCase(loadProduct.fulfilled, (state: ProductState, action: PayloadAction<LoadProductResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.partners = action.payload.partners;
            state.referenceDate = action.payload.referenceDate;
            state.productCategories = action.payload.productCategories;
            state.rangeOverviewProductCategories = action.payload.rangeOverviewProductCategories;
        });
        builder.addCase(loadStores.pending, (state: ProductState) => {
            state.stores.isLoading = true;
            state.stores.hasErrors = false;
            state.stores.data = [];
        });
        builder.addCase(loadStores.rejected, (state: ProductState) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = true;
            state.stores.data = [];
        });
        builder.addCase(loadStores.fulfilled, (state: ProductState, action: PayloadAction<Store[]>) => {
            state.stores.data = action.payload;
            state.stores.isLoading = false;
            state.stores.hasErrors = false;
        });
        builder.addCase(loadProducts.pending, (state: ProductState) => {
            state.products.isLoading = true;
            state.products.hasErrors = false;
            state.products.data = [];
        });
        builder.addCase(loadProducts.rejected, (state: ProductState) => {
            state.products.isLoading = false;
            state.products.hasErrors = true;
            state.products.data = [];
        });
        builder.addCase(loadProducts.fulfilled, (state: ProductState, action: PayloadAction<Product[]>) => {
            state.products.data = action.payload;
            state.products.isLoading = false;
            state.products.hasErrors = false;
        });
        builder.addCase(loadFilterStores.pending, (state: ProductState) => {
            state.filterStores.isLoading = true;
            state.filterStores.hasErrors = false;
            state.filterStores.data = [];
        });
        builder.addCase(loadFilterStores.rejected, (state: ProductState) => {
            state.filterStores.isLoading = false;
            state.filterStores.hasErrors = true;
            state.filterStores.data = [];
        });
        builder.addCase(loadFilterStores.fulfilled, (state: ProductState, action: PayloadAction<Store[]>) => {
            state.filterStores.data = action.payload;
            state.filterStores.isLoading = false;
            state.filterStores.hasErrors = false;
        });
    }
});

export const {
    setCurrentChapter,
    choosePartner,
    chooseStoreAndRange,
    clearSelectedStore,
    clearSelectedRange,
    toggleRangeOverviewProductCategoryIsSelected,
    chooseAllRangeOverviewProductCategories,
    deselectAllRangeOverviewProductCategories
} = productSlice.actions;

export const loadProduct = createAppAsyncThunk(
    "customer/tools/location/loadProduct",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const referenceDate = await thunkAPI.dispatch(loadReferenceDate());
            const partnersPromise = thunkAPI.dispatch(loadPartners(referenceDate));
            const productCategoriesPromise = thunkAPI.dispatch(loadProductCategories(referenceDate));

            const results = await Promise.all([partnersPromise, productCategoriesPromise]);
            const partners = results[0];
            const productCategories = results[1];

            const rangeOverviewProductCategories = productCategories.map(category => new ProductCategoryMultiSelect(
                category,
                true
            ));

            const loadProductResponse: LoadProductResponse = {
                partners,
                referenceDate,
                productCategories,
                rangeOverviewProductCategories
            };
            return loadProductResponse;
        } catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Product."));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearProduct = (): AppThunk => async (dispatch) => {
    dispatch(productSlice.actions.resetCurrentChapter());
    dispatch(productSlice.actions.clearPartners());
    dispatch(productSlice.actions.clearReferenceDate());
    dispatch(productSlice.actions.clearProductCategories());
    dispatch(productSlice.actions.clearRangeOverviewProductCategories());
    dispatch(productSlice.actions.clearSelectedPartner());
    dispatch(clearPartnerFilters());
    dispatch(clearInsights());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const partner = selectSelectedPartner(state);
        const referenceDate = selectReferenceDate(state);
        const selectedStore = selectSelectedStore(state);
        const selectedRange = selectSelectedRange(state);

        dispatch(loadStores({ referenceDate, partner, selectedStore, selectedRange }));
        dispatch(loadProducts({ referenceDate, partner, selectedStore, selectedRange }));
        if (selectedStore) {
            dispatch(loadStoreOpportunities());
        }
        if (selectedRange) {
            dispatch(loadRangeOpportunities());
        }
        if (selectedStore && selectedRange) {
            dispatch(loadProductStoreFit());
        }
    } catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const loadStoreFilters = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const partner = selectSelectedPartner(state);
        const referenceDate = selectReferenceDate(state);
        dispatch(loadFilterStores({ referenceDate, partner }));
    } catch (error) {
        dispatch(logError("Error loading Store Filters.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(productSlice.actions.clearStores());
    dispatch(clearStoreOpportunities());
    dispatch(clearProductStoreFit());
};

export const clearStoreRangeFilters = (): AppThunk => (dispatch) => {
    dispatch(productSlice.actions.clearSelectedStore());
    dispatch(productSlice.actions.clearSelectedRange());
    dispatch(productSlice.actions.clearFilterStores());
    dispatch(resetStoreRangeFilters());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.tools.product.root.currentChapter;
};

export const selectPartners = (state: RootState) => {
    return state.customer.tools.product.root.partners;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.tools.product.root.referenceDate;
};

export const selectProductCategories = (state: RootState) => {
    return state.customer.tools.product.root.productCategories;
};

export const selectStores = (state: RootState) => {
    return state.customer.tools.product.root.stores;
};

export const selectProducts = (state: RootState) => {
    return state.customer.tools.product.root.products;
};

export const selectFilterStores = (state: RootState) => {
    return state.customer.tools.product.root.filterStores;
};

export const selectSelectedPartner = (state: RootState) => {
    return state.customer.tools.product.root.selectedPartner;
};

export const selectSelectedStore = (state: RootState) => {
    return state.customer.tools.product.root.selectedStore;
};

export const selectSelectedRange = (state: RootState) => {
    return state.customer.tools.product.root.selectedRange;
};

export const selectRangeOverviewProductCategories = (state: RootState) => {
    return state.customer.tools.product.root.rangeOverviewProductCategories;
};

export const openStreetView = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const selectedStore = selectSelectedStore(state);
    const latitude = selectedStore?.latitude ?? 0;
    const longitude = selectedStore?.longitude ?? 0;
    dispatch(mapOpenStreetView(latitude, longitude));
};

export const selectStoresTotalSales = createSelector(
    selectStores,
    (stores) => {
        const totalOptimisedSales = stores.data.reduce((total, current) => total + current.sales.optimisedSales, 0);
        const totalEstimatedSales = stores.data.reduce((total, current) => total + current.sales.estimatedSales, 0);
        const totalClientSourcedSales = stores.data.reduce((total, current) => total + current.sales.clientSourcedSales, 0);
        const totalSalesHeadroom = stores.data.map(store => store.sales.salesHeadroom)
            .filter(headroom => headroom > 0)
            .reduce((total, current) => total + current, 0);

        return {
            isLoading: stores.isLoading,
            hasErrors: stores.hasErrors,
            data: {
                totalOptimisedSales,
                totalEstimatedSales,
                totalClientSourcedSales,
                totalSalesHeadroom
            }
        };
    }
);

export const selectFlattenedStores = createSelector(
    selectStores,
    (stores) => {
        return {
            isLoading: stores.isLoading,
            hasErrors: stores.hasErrors,
            data: stores.data.map(store => ({
                ...store,
                ...store.sales
            }))
        };
    }
);

export const selectFlattenedProducts = createSelector(
    selectProducts,
    (products) => {
        return {
            isLoading: products.isLoading,
            hasErrors: products.hasErrors,
            data: products.data.map(product => ({
                ...product,
                ...product.sales
            }))
        };
    }
);

export const selectProductsTotalSales = createSelector(
    selectProducts,
    (products) => {
        const totalOptimisedSales = products.data.reduce((total, current) => total + current.sales.optimisedSales, 0);
        const totalEstimatedSales = products.data.reduce((total, current) => total + current.sales.estimatedSales, 0);
        const totalClientSourcedSales = products.data.reduce((total, current) => total + current.sales.clientSourcedSales, 0);
        const totalSalesHeadroom = products.data.map(product => product.sales.salesHeadroom)
            .filter(headroom => headroom > 0)
            .reduce((total, current) => total + current, 0);

        return {
            isLoading: products.isLoading,
            hasErrors: products.hasErrors,
            data: {
                totalOptimisedSales,
                totalEstimatedSales,
                totalClientSourcedSales,
                totalSalesHeadroom
            }
        };
    }
);

export const selectRangeOverviewFilteredProducts = createSelector(
    selectProducts,
    selectRangeOverviewProductCategories,
    (products, productCategories) => {
        const selectedCategories = productCategories.filter(category => category.isSelected()).map(category => category.name);
        return {
            ...products,
            data: products.data.filter(product => selectedCategories.includes(product.productCategory))
        };
    }
);

export default productSlice;
